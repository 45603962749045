/*	First main color: BLUE

blue - #3677a1
light blue - #30a0ba

*/

$main_color1: #3677a1;
$main_color2: #e8554e;
/* Base
--------------------------------------------------- */
h1, a { color: $main_color1; } 
.colored-text-1 { color: $main_color1 !important; }
.colored-background-1 { background-color: $main_color1 !important; }

/* Page Title, Secondary Navigation & Headline
--------------------------------------------------- */
.breadcrumb-nav li { background-image: url('/images/colored_theme/link-arrow-small-colored-1.png'); }

/* Secondary Navigation Elements & Links
--------------------------------------------------- */
a.link-lg { background-image: url('/images/colored_theme/link-arrow-long-colored-1.png'); }
a.link-sm { background-image: url('/images/colored_theme/link-arrow-small-colored-1.png'); }

/* Typography
--------------------------------------------------- */
.dropcap.colored:first-letter { color: $main_color1; }


/*	Second main color: RED

red - #e8554e / rgb(232, 85, 78)

*/

/* Base
--------------------------------------------------- */
.colored-text-2 { color: $main_color2 !important; }
.colored-background-2 { background-color: $main_color2 !important; }
a:hover, a > *:hover { color: $main_color2; }
a:hover .title, a.title:hover { color: $main_color2 !important; }
a:hover h4.italic.title span,
a.italic:hover { border-bottom-color: $main_color2; }
.button.colored, input[type="submit"].button.colored:active {
	background-color: $main_color2;
	background: -webkit-linear-gradient(top, rgba(232, 85, 78, 0.8), $main_color2);
	background: -moz-linear-gradient(top, rgba(232, 85, 78, 0.8), $main_color2);
	background: -o-linear-gradient(top, rgba(232, 85, 78, 0.8), $main_color2);
	background: -ms-linear-gradient(top, rgba(232, 85, 78, 0.8), $main_color2);
	background: linear-gradient(to bottom, rgba(232, 85, 78, 0.8), $main_color2);
}
.button.colored:hover { background-color: $main_color2; }

/* Header Menu
--------------------------------------------------- */
#primary-nav > ul > li > a.current { color: $main_color2 !important; }

/* Homepage Hero
--------------------------------------------------- */
.slider-menu.thumbnails li.active a { border-color: $main_color2 !important; }
#home-hero-nav .slider-caption > div {
	background-color: $main_color2;
	background: rgba(232, 85, 78, 0.5);
}
.intro-content .number-box { color: $main_color2; }
.intro-content .number-box + * { border-left-color: $main_color2; }

/* Homepage Intro
--------------------------------------------------- */
.intro-wrapper { background-image: url("/images/colored_theme/intro-shadow.png"); }

/* Page Title, Secondary Navigation & Headline
--------------------------------------------------- */
#subheader-bar .sub-nav li.current a { color: $main_color2; }

/* FlexSlider
--------------------------------------------------- */
.flex-caption.colored {
	background-color: $main_color2;
	background: rgba(232, 85, 78, 0.5);
}
.flex-direction-nav .flex-prev:hover, .flex-direction-nav .flex-next:hover { background-color: rgba(232, 85, 78, 0.5); }
.ie8 .flex-direction-nav .flex-prev:hover, .ie8 .flex-direction-nav .flex-next:hover { background-color: $main_color2; }
.flex-control-paging li a:hover span { border-color: $main_color2; }
.flex-control-paging li a.flex-active span {
	background-color: $main_color2;
	border-color: $main_color2;
}

/* Portfolio, Projects Carousel & Gallery
--------------------------------------------------- */
.portfolio-item-preview .item-description:before {
	background-image: -webkit-linear-gradient(left, $main_color2, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), $main_color2);
	background-image: -moz-linear-gradient(left, $main_color2, rgba(255, 255, 255, 0), $main_color2);
	background-image: -o-linear-gradient(left, $main_color2, rgba(255, 255, 255, 0), $main_color2);
	background-image: -ms-linear-gradient(left, $main_color2, rgba(255, 255, 255, 0), $main_color2);
}
.portfolio-item-preview .extra-tags .price,
#portfolio-filter li a.current { color: $main_color2; }

/* Team
--------------------------------------------------- */
.team .sub { color: $main_color2; }

/* Blog
--------------------------------------------------- */
.blog-grid-style .post-media + .post-content:before {
	background-image: -webkit-linear-gradient(left, $main_color2, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), $main_color2);
	background-image: -moz-linear-gradient(left, $main_color2, rgba(255, 255, 255, 0), $main_color2);
	background-image: -o-linear-gradient(left, $main_color2, rgba(255, 255, 255, 0), $main_color2);
	background-image: -ms-linear-gradient(left, $main_color2, rgba(255, 255, 255, 0), $main_color2);
}
.comment .date a:hover, .comment .date a > *:hover { color: $main_color2; }

/* Info Box
--------------------------------------------------- */
.info-box.colored { background-color: $main_color2; }

/* Tabs
--------------------------------------------------- */
.tabs-nav li.active a, .tabs-nav li.active a:hover { background-color: $main_color2; }
.tabs-nav li.active:before { background-image: url("/images/colored_theme/tab-arrow.png"); }
@media only screen and (min-width: 768px) {
	.white-bg .tabs-nav li.active a { background-color: $main_color2; }
	.tabs-nav.bordered li.active a,
	.white-bg .tabs-nav.bordered li.active a { border-bottom-color: $main_color2; }
}

/* Twitter Feed
--------------------------------------------------- */
.tweets .tweet_time a:hover,
aside .tweets .tweet_time a:hover { color: $main_color2; }

/* Secondary Navigation Elements & Links
--------------------------------------------------- */
a.link-lg.colored-text-2, a.link-lg:hover {
	background-image: url("/images/colored_theme/link-arrow-long-colored-2.png");
}
a.link-sm.colored-text-2, a.link-sm:hover {
	background-image: url("/images/colored_theme/link-arrow-small-colored-2.png");
}
a.link-lg:hover, a.link-sm:hover { color: $main_color2 !important; }
a.link-lg span, a.link-sm span { border-bottom-color: $main_color2; }
.pagination span.current { border-color: $main_color2; background-color: $main_color2; }
ul.list-nav a:hover { color: $main_color2; }
ul.list-nav.arrow-list:not(.table) a:hover {
	background-image: url("/images/colored_theme/list-marker-arrow-colored.png");
}
ul.list-nav.arrow-list.italic:not(.table) a:hover {
	background-image: url("/images/colored_theme/list-marker-arrow-oblique-colored.png");
}
ul.list-nav.square-list:not(.table) a:hover {
	background-image: url("/images/colored_theme/list-marker-square-colored.png");
}
ul.list-nav.table.colored-text-1 a:hover,
ul.list-nav.table.colored-text-1 a:hover *,
ul.list-nav.table.colored-text-1 li.current a,
ul.feature-nav a > span .fa { color: $main_color2; }

/* Pricing Tables
--------------------------------------------------- */
.pricing-table .header:before,
.pricing-table .footer:before {
	background-image: -webkit-linear-gradient(left, $main_color2, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), $main_color2);
	background-image: -moz-linear-gradient(left, $main_color2, rgba(255, 255, 255, 0), $main_color2);
	background-image: -o-linear-gradient(left, $main_color2, rgba(255, 255, 255, 0), $main_color2);
	background-image: -ms-linear-gradient(left, $main_color2, rgba(255, 255, 255, 0), $main_color2);
}
.pricing-table.extended .features li .check {
	background-image: url("/images/colored_theme/list-marker-check-colored.png");
}

/* Contact + Form + Google Map
--------------------------------------------------- */
.contact-info .fa { color: $main_color2; }

/* Typography
--------------------------------------------------- */
.highlight.dotted { border-bottom-color: $main_color2; }
.highlight.colored { background-color: $main_color2; }
.check-list.colored li { background-image: url("/images/colored_theme/list-marker-check-colored.png"); }
.arrow-list.colored li { background-image: url("/images/colored_theme/list-marker-arrow-colored.png"); }
.arrow-list.italic.colored li {
	background-image: url("/images/colored_theme/list-marker-arrow-oblique-colored.png");
}
.dash-list.colored li { background-image: url("/images/colored_theme/list-marker-dash-colored.png"); }
.square-list.colored li { background-image: url("/images/colored_theme/list-marker-square-colored.png"); }
.circle-list.colored li { background-image: url("/images/colored_theme/list-marker-circle-colored.png"); }



/*	Main background color scheme: BLUE

blue 1 - #f7f7f8
blue 2 - #eef0f2
blue 3 - #eaedef
blue 4 - #e5e8ea
blue 5 - #c3c9cf / rgb(195, 201, 207)
blue 6 - #adb3b9
blue 7 - #92979c / rgb(146, 151, 156)
*/

/*-----------------------------------------------------------------------------------*/
/*	Blue 1 - #f7f7f8
/*-----------------------------------------------------------------------------------*/

/* Base */
body { background-color: #f7f7f8; }

/* Homepage Hero */
#home-hero-content,
.slider-menu,
.slider-menu li a { background-color: #f7f7f8; }
.slider-menu li.active a:hover, .slider-menu li.active a:hover * { color: #f7f7f8; }
.slider-menu.thumbnails li a { border-color: #f7f7f8 !important; }

/* Homepage Intro */
.intro-wrapper:before { background-image: url("/images/colored_theme/intro-bg.png"); }

/* Page Title, Secondary Navigation & Headline */
#page-title.custom-bg { background-color: #f7f7f8; }

/* Image and Preview Image: Overlay & Border */
.thumbnail-colored { background-color: #f7f7f8; }

/* Sidebar */
#sidebar.page-left-col .side-menu li.current,
#sidebar.page-left-col .side-menu li.current:hover {
	background-image: url("/images/colored_theme/current-bg-left-side-menu.png");
}
#sidebar.page-right-col .side-menu li.current,
#sidebar.page-right-col .side-menu li.current:hover {
	background-image: url("/images/colored_theme/current-bg-right-side-menu.png");
}

/* Tabs */
@media only screen and (min-width: 768px) {
	.tabs-nav.bordered li { background-color: #f7f7f8; }
}

/* Contact + Form + Google Map */
.google-map { background-color: #f7f7f8 !important; }


/*-----------------------------------------------------------------------------------*/
/*	Blue 2 - #eef0f2
/*-----------------------------------------------------------------------------------*/

/* Document Setup */
#sidebar { background-color: #eef0f2; }
.stripe.top { background: #eef0f2; }

/* Header Menu */
body.sticky-menu-active #logo { background-color: #eef0f2; }

/* Homepage Intro */
@media only screen and (min-width: 768px) and (max-width: 959px) {
	.intro-content .preview-block { background-color: #eef0f2; }
}

/* Page Title, Secondary Navigation & Headline */
#subheader-bar { border-bottom-color: #eef0f2; }

/* Sidebar */
#sidebar .widget.separator h4:after,
#sidebar .widget.separator h5:after {
	background: -webkit-linear-gradient(left, #eef0f2, #d5d5d5 5%, #d5d5d5 95%, #eef0f2);
	background: -moz-linear-gradient(left, #eef0f2, #d5d5d5 5%, #d5d5d5 95%, #eef0f2);
	background: -o-linear-gradient(left, #eef0f2, #d5d5d5 5%, #d5d5d5 95%, #eef0f2);
	background: -ms-linear-gradient(left, #eef0f2, #d5d5d5 5%, #d5d5d5 95%, #eef0f2);
	background: linear-gradient(to right, #eef0f2, #d5d5d5 5%, #d5d5d5 95%, #eef0f2);
}

/* Blog */
.blog .post-date .day,
.blog .post-date .month { background-color: #eef0f2; }

/* Info Box */
.info-box.light { background-color: #eef0f2; }

/* Accordion / Toggle */
.toggle-trigger { background-color: #eef0f2; }

/* Pricing Tables */
.pricing-table .price { background-color: #eef0f2; }

/* Other Elements */
table.standard tr:hover td { background-color: #eef0f2; }


/*-----------------------------------------------------------------------------------*/
/*	Blue 3 - #eaedef
/*-----------------------------------------------------------------------------------*/

/* Document Setup */
.grid-gradient-bg { background-image: url("/images/colored_theme/grid-gradient-bg.png"); }
.stripe { background-color: #eaedef; }
.stripe.white-bg {
	border-top-color: #eaedef;
	border-bottom-color: #eaedef;
}
.stripe.bottom.map-wrapper { border-bottom-color: #eaedef; }

/* Homepage Featured Content */
#home-featured-content { background-color: #eaedef; }

/* Page Title, Secondary Navigation & Headline */
#page-title { background-color: #eaedef; }

/* Single Item Details Page */
.single-item-details .info-board.colored { background-color: #eaedef; }

/* Footer */
#footer *,
#footer-bottom .footer-nav a,
#footer-bottom .links a:hover { color: #eaedef; }
#footer .flickr-widget .flickr_badge_image { background-color: #eaedef; }

/* Sidebar */
#sidebar.page-left-col .side-menu li:hover {
	background-image: url("/images/colored_theme/hover-bg-left-side-menu.png");
}
#sidebar.page-right-col .side-menu li:hover {
	background-image: url("/images/colored_theme/hover-bg-right-side-menu.png");
}

/* Info Box */
.info-box { background-color: #eaedef; }

/* Tabs */
.tabs-nav li a { background-color: #eaedef; }

/* Social Icons */
.social-icons li:hover { background-color: #eaedef; }

/* 	Secondary Navigation Elements & Links */
ul.list-nav.table li { background-color: #eaedef; }

/* Other Elements */
table.standard th { background-color: #eaedef; }

/* Misc. */
.grid-col { background-color: #eaedef; }


/*-----------------------------------------------------------------------------------*/
/*	Blue 4 - #e5e8ea
/*-----------------------------------------------------------------------------------*/

/* Document Setup */
.stripe { /* !!! 2 colors */
	border-top-color: #e5e8ea;
	border-bottom-color: #e5e8ea;
	background: -webkit-linear-gradient(top, #e5e8ea, #eaedef 20px);
	background: -moz-linear-gradient(top, #e5e8ea, #eaedef 20px);
	background: -o-linear-gradient(top, #e5e8ea, #eaedef 20px);
	background: -ms-linear-gradient(top, #e5e8ea, #eaedef 20px);
	background: linear-gradient(to bottom, #e5e8ea, #eaedef 20px);
}

/* Header Menu */
body.sticky-menu-active #logo { border-color: #e5e8ea; }

/* Homepage Intro */
@media only screen and (min-width: 768px) and (max-width: 959px) {
	.intro-content .preview-block { border-color: #e5e8ea; }
}

/* Homepage Featured Content */
#home-featured-content { /* !!! 2 colors */
	background: -webkit-linear-gradient(bottom, #e5e8ea, #eaedef 30px);
	background: -moz-linear-gradient(bottom, #e5e8ea, #eaedef 30px);
	background: -o-linear-gradient(bottom, #e5e8ea, #eaedef 30px);
	background: -ms-linear-gradient(bottom, #e5e8ea, #eaedef 30px);
	background: linear-gradient(to top, #e5e8ea, #eaedef 30px);
}

/* Page Title, Secondary Navigation & Headline */
#page-title { /* !!! 2 colors */
	background: -webkit-linear-gradient(bottom, #e5e8ea, #eaedef 30px);
	background: -moz-linear-gradient(bottom, #e5e8ea, #eaedef 30px);
	background: -o-linear-gradient(bottom, #e5e8ea, #eaedef 30px);
	background: -ms-linear-gradient(bottom, #e5e8ea, #eaedef 30px);
	background: linear-gradient(to top, #e5e8ea, #eaedef 30px);
}

/* Info Box */
.info-box,
.info-box.light { border-color: #e5e8ea; }
.info-box.light.gradient { /* !!! 2 colors */
	background: -webkit-linear-gradient(bottom, #f7f7f8, #e5e8ea);
	background: -moz-linear-gradient(bottom, #f7f7f8, #e5e8ea);
	background: -o-linear-gradient(bottom, #f7f7f8, #e5e8ea);
	background: -ms-linear-gradient(bottom, #f7f7f8, #e5e8ea);
	background: linear-gradient(to top, #f7f7f8, #e5e8ea);
}

/* Accordion / Toggle */
.toggle-trigger,
.toggle-container { border-color: #e5e8ea; }

/* Tabs */
.tabs-nav li a:hover { background-color: #e5e8ea; }
.white-bg .tabs-container.bordered,
.white-bg .tabs-container.bordered-all { border-bottom-color: #e5e8ea; }
@media only screen and (min-width: 768px) {
	.white-bg .tabs-nav.bordered li a { border-bottom-color: #e5e8ea; }
	.white-bg .tabs-container.bordered-all { border-color: #e5e8ea; }
}

/* 	Secondary Navigation Elements & Links */
ul.list-nav.table li:hover,
ul.list-nav.table li.current,
ul.feature-nav a { background-color: #e5e8ea; }

/* Pricing Tables */
.pricing-table .table-column:first-child .price,
.pricing-table .table-column:first-child .features { border-left-color: #e5e8ea; }
.pricing-table .table-column:last-child .price,
.pricing-table .table-column:last-child .features { border-right-color: #e5e8ea; }
.pricing-table .footer { background-color: #e5e8ea; }
@media only screen and (max-width: 959px) {
	.pricing-table.extended .features-list + .table-column .features { border-left-color: #e5e8ea; }
}

/* Misc. */
.ie8 .error-404 { color: #e5e8ea; }


/*-----------------------------------------------------------------------------------*/
/*	Blue 5 - #c3c9cf / rgb(195, 201, 207)
/*-----------------------------------------------------------------------------------*/

/* Base */
.section-header.colored:before, .section-header.colored:after { background-color: #c3c9cf; }
h3.underlined.colored, h4.underlined.colored, h5.underlined.colored, h6.underlined.colored,
.divider-line.colored {
	border-bottom-color: #c3c9cf;
}
h3.underlined.dashed.colored, h4.underlined.dashed.colored, h5.underlined.dashed.colored, h6.underlined.dashed.colored,
.divider-dashline.colored {
	background-image: url("/images/colored_theme/dashed-line-horizontal-colored.png");
}
.divider-pattern.streaks.colored {
	background-image: url("/images/colored_theme/divider-streak-pattern-colored.png");
}
.divider-pattern.wave.colored {
	background-image: url("/images/colored_theme/divider-wave-pattern-colored.png");
}
input[type="text"], input[type="password"], input[type="email"], textarea, select { border-color: #c3c9cf; }

/* Document Setup */
.stripe.bottom { border-top-color: #c3c9cf; }

/* Header Menu */
#primary-nav ul ul li.divider:after { border-bottom-color: #c3c9cf; }

/* Page Title, Secondary Navigation & Headline */
#page-title { border-bottom-color: #c3c9cf; }

/* FlexSlider */
.flex-control-paging li a span { border-color: #c3c9cf; }

/* Portfolio, Projects Carousel & Gallery */
.portfolio-item-preview .item-description:before { background-color: #c3c9cf; }
#portfolio-wrapper.one-col .portfolio-item-preview { border-top-color: #c3c9cf; }

/* Image and Preview Image: Overlay & Border */
.thumbnail-colored { border-color: #c3c9cf; }

/* Single Item Details Page */
.small-bar.colored { border-color: #c3c9cf; }
@media only screen and (min-width: 768px) and (max-width: 959px) {
	#main-content .single-item-details .text-description { border-top-color: #c3c9cf; }
}
@media only screen and (max-width: 767px) {
	.single-item-details .text-description { border-top-color: #c3c9cf; }
}

/* Footer */
#footer input[type="email"] { border-color: #c3c9cf; }
#footer input[type="submit"] { background-color: #c3c9cf; }
#footer-bottom .links * { color: #c3c9cf; }
#footer .scroll-top,
.stripe .scroll-top { border-left-color: #c3c9cf; }
#footer .button { /* !!! 2 colors */
	background-color: #adb3b9;
	background-color: rgba(195, 201, 207, 0.4);
}
#footer .button:hover { background-color: rgba(195, 201, 207, 0.5); }

/* Sidebar */
#sidebar.page-left-col .side-menu li a:hover,
#sidebar.page-left-col .side-menu li.current a,
#sidebar.page-left-col .side-menu li a:after,
.ie8 #sidebar.page-left-col .side-menu li:hover + li.current a,
.ie8 #sidebar.page-left-col .side-menu li.current + li:hover a {
	background-image: url("/images/colored_theme/separator-left-side-menu-colored.png");
}
#sidebar.page-right-col .side-menu li a:hover,
#sidebar.page-right-col .side-menu li.current a,
#sidebar.page-right-col .side-menu li a:after,
.ie8 #sidebar.page-right-col .side-menu li:hover + li.current a,
.ie8 #sidebar.page-right-col .side-menu li.current + li:hover a {
	background-image: url("/images/colored_theme/separator-right-side-menu-colored.png");
}
@media only screen and (max-width: 767px) {
	.side-menu li { border-top-color: #c3c9cf; }
}

/* Blog */
.blog-grid-style .post-media + .post-content:before { background-color: #c3c9cf; }
.blog article,
.comments-list { border-bottom-color: #c3c9cf; }
.comment,
.comment .children .comment { border-top-color: #c3c9cf; }

/* Announcements */
.announcements article { border-top-color: #c3c9cf; }
@media only screen and (max-width: 959px) {
	.announcements section.adapted-max-959px article:first-child { border-top-color: #c3c9cf; }
}
@media only screen and (max-width: 767px) {
	.announcements section.adapted-max-767px article:first-child,
	.announcements .row:first-child article { border-top-color: #c3c9cf; }
}

/* Info Box */
.info-box.action-box { border-left-color: #c3c9cf; }

/* Tabs */
.tabs-container.bordered,
.tabs-container.bordered-all { border-bottom-color: #c3c9cf; }
@media only screen and (min-width: 768px) {
	.tabs-nav.bordered, .tabs-nav.bordered li a { border-bottom-color: #c3c9cf; }
	.tabs-container.bordered-all {
		border-left-color: #c3c9cf;
		border-right-color: #c3c9cf;
		border-bottom-color: #c3c9cf;
	}
}
@media only screen and (max-width: 767px) {
	.white-bg .tabs-container.bordered { border-bottom-color: #c3c9cf; }
}

/* Message Boxes */
.message-box { border-color: #c3c9cf; }

/* Twitter Feed */
.tweets .tweet_list li:before { color: #c3c9cf; }

/* Secondary Navigation Elements & Links */
ul.list-nav.ruled li { border-top-color: #c3c9cf; }
ul.feature-nav a:hover { background-color: rgba(195, 201, 207, 0.5); }
ul.list-nav.table li:hover,
ul.list-nav.table li.current { background: rgba(195, 201, 207, 0.4); }

/* Pricing Tables */
.pricing-table .header:before,
.pricing-table .footer:before { background-color: #c3c9cf; }
.ie .pricing-table .header { border-bottom-color: #c3c9cf; }
.pricing-table .header,
.pricing-table .features,
.pricing-table .footer,
.ie8 .pricing-table .featured .footer,
aside .pricing-table .table-column:first-child .features,
aside .pricing-table .table-column:first-child .price { border-left-color: #c3c9cf; }
aside .pricing-table .table-column:last-child .price,
aside .pricing-table .table-column:last-child .features { border-right-color: #c3c9cf; }

/* Other Elements */
table.standard th,
table.standard td { border-color: #c3c9cf; }
table.standard tr:hover td { background-color: rgba(195, 201, 207, 0.1); }


/*-----------------------------------------------------------------------------------*/
/*	Blue 6 - #adb3b9
/*-----------------------------------------------------------------------------------*/

/* Base */
.colored-text-pale { color: #424242 !important; }

/* Document Setup */
.fa-icon-list a .fa { color: #adb3b9; }

/* Header Menu */
#secondary-nav .phone-number { color: #adb3b9; }

/* Homepage Hero */
.slider-menu li a { border-left-color: #424242; }
.slider-menu li a, .slider-menu li a * { color: #424242; }

/* Footer */
#footer.bottom-separated .container,
#footer .underlined.colored,
#footer .button { border-bottom-color: #adb3b9; }
#footer .contact-description > div {
	border-left-color: #adb3b9;
}
#footer-bottom .links li span { color: #adb3b9; }

/* Sidebar */
.side-menu li span { color: #adb3b9; }

/* Blog */
.blog .post-meta .fa { color: #adb3b9; }

/* Social Icons */
.social-icons li,
.ie8 #main .social-icons li,
.social-icons.small-size li { background-color: #424242; }

/* Secondary Navigation Elements & Links */
.pagination a, .pagination > span,
.pagination span.pages,
ul.list-nav span { color: #adb3b9; }

/* Pricing Tables */
.pricing-table .header { background-color: #adb3b9; }

/* Other Elements */
table.standard th { border-bottom-color: #adb3b9; }

/* Misc. */
.error-404 { color: #adb3b9; }


/*-----------------------------------------------------------------------------------*/
/*	Blue 7 - #92979c / rgb(146, 151, 156)
/*-----------------------------------------------------------------------------------*/

/* Base */
.button, input[type="submit"], input[type="submit"].button:active, input[type="reset"], input[type="button"] {
	background-color: #92979c;
	background: -webkit-linear-gradient(top, rgba(146, 151, 156, 0.8), #92979c);
	background: -moz-linear-gradient(top, rgba(146, 151, 156, 0.8), #92979c);
	background: -o-linear-gradient(top, rgba(146, 151, 156, 0.8), #92979c);
	background: -ms-linear-gradient(top, rgba(146, 151, 156, 0.8), #92979c);
	background: linear-gradient(to bottom, rgba(146, 151, 156, 0.8), #92979c);
}
.button:hover, input[type="submit"]:hover, input[type="reset"]:hover, input[type="button"]:hover {
	background-color: #92979c;
}

/* Document Setup */
.fa-icon-list a:hover .fa { color: #92979c; }

/* Header Menu */
body.sticky-menu-active #logo { background-image: url("/images/colored_theme/scroll-top-arrow.png"); }

/* Homepage Hero */
.slider-menu li.active a {
	background-color: #B20000;
	border-left-color: #B20000;
}
.slider-menu li.active + li a { border-left-color: #92979c; }

/* Homepage Intro */
.video-preview .overlay {
	background-color: #92979c;
	background: rgba(146, 151, 156, 0.2);
}
.intro-content .video-preview .overlay {
	background-color: #92979c;
	background: rgba(146, 151, 156, 0);
}
.no-touch-screen .video-preview a[data-rel=prettyPhoto]:hover .overlay { background: rgba(146, 151, 156, 0.4); }
.no-touch-screen .intro-content .video-preview a[data-rel=prettyPhoto]:hover .overlay { background: rgba(146, 151, 156, 0.9); }
.ie8 .video-preview .overlay {
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#3392979c,endColorstr=#3392979c);
}
.ie8 .video-preview a[data-rel=prettyPhoto]:hover .overlay {
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#6692979c,endColorstr=#6692979c);
}
.ie8 .intro-content .video-preview .overlay {
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#0092979c,endColorstr=#0092979c);
}
.ie8 .intro-content .video-preview a[data-rel=prettyPhoto]:hover .overlay {
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#df92979c,endColorstr=#df92979c);
}

/* Page Title, Secondary Navigation & Headline */
#subheader-bar .sub-nav a { color: #92979c; }

/* Footer */
#footer,
#footer input[type="submit"]:hover,
#footer-bottom { background-color: #0E457A; }
#footer .scroll-top .arrow-top,
.stripe .scroll-top .arrow-top { background-image: url('/images/colored_theme/scroll-top-arrow.png'); }
#footer .scroll-top span,
.stripe .scroll-top span { color: #92979c; }

/* Sidebar */
.side-menu.middle-font-size a { color: #92979c; }

/* Info Box */
.info-box.dark { background-color: #92979c; }

/* Social Icons */
.social-icons li { background-image: url("/images/colored_theme/social-icons-colored.png"); }
#main .social-icons li:hover,
.ie8 #main .social-icons li:hover,
.social-icons.small-size li:hover { background-color: #424242; }

/* Pricing Tables */
.pricing-table .featured .header { background-color: #92979c; }
