/* ---------------------------------------------------------------------------------------------

	1.	Document Setup
	2.	Header Menu
	3.	Homepage Hero
	4.	Homepage Intro
	5.	Homepage Featured Content
	6.	Page Title, Secondary Navigation & Headline
	7.	FlexSlider
	8.	Feature Boxes
	9.	Portfolio, Projects Carousel & Gallery
	10.	Image and Preview Image: Overlay & Border
	11.	Single Item Details Page
	12.	Team
	13.	Footer
	14.	Sidebar
	15.	Blog
	16.	Announcements
	17.	Info Box
	18.	Testimonials
	19.	Accordion / Toggle
	20.	Tabs
	21.	Message Boxes
	22.	Flickr Widget (Photostream)
	23.	Preview Images List
	24.	Twitter Feed
	25.	Social Icons
	26.	Secondary Navigation Elements & Links
	27.	Pricing Tables
	28.	Contact + Form + Google Map
	29.	Other Elements
	30.	Typography
	31.	Misc.
	
	32.	Media Queries

*/


/*-----------------------------------------------------------------------------------*/
/*	1. Document Setup
/*-----------------------------------------------------------------------------------*/

#header {
	z-index: 4;
	position: relative;
	width: 100%;
	background-color: #fff;
	-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	   -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
#header .columns { position: relative; }
#top-bar {
	width: 100%;
	background-color: #0E457A;
	border-bottom: 1px solid #f5f5f5;
	padding: 8px 0 9px;
	font-size: 12px;
	line-height: 19px;
}
#logo {
	float: left;
	position: absolute;
	left: 0;
	bottom: 5px;
	background: white;
	padding: 0 30px;
	border-radius: 60px;
	box-shadow: 0px -8px 6px -6px #888888;
}
#main {
	z-index: 1;
	position: relative;
}
#page-content { padding: 50px 0 60px; }

/* Page layout with sidebar
--------------------------------------------------- */
#page-content.sidebar-layout {
	padding: 0;
	overflow: hidden;
}
#page-content.sidebar-layout .columns-wrapper { display: table; }
#page-content.sidebar-layout .columns-wrapper.sb-right { margin-right: -790px; }
#page-content.sidebar-layout .columns-wrapper.sb-left { margin-left: -790px; }
#main-content {
	display: table-cell;
	float: none;
	margin: 0;
	padding-top: 50px;
	padding-bottom: 60px;
	vertical-align: top;
}
#main-content.page-left-col { width: 780px; padding-right: 40px; }
#main-content.page-right-col { width: 840px; padding-left: 40px; }
#sidebar {
	display: table-cell;
	float: none;
	margin: 0;
	background-color: #f0f2ed;
	background-repeat: repeat-y;
	vertical-align: top;
}
#sidebar.page-left-col {
	width: 160px;
	padding: 50px 30px 60px 800px;
	background-image: url("/images/backgrounds/border-left-sidebar.png");
	background-position: right top;
}
#sidebar.page-right-col {
	width: 220px;
	padding: 50px 800px 60px 30px;
	background-image: url("/images/backgrounds/border-right-sidebar.png");
	background-position: left top;
}

/* Grid backgrounds
--------------------------------------------------- */
.grid-bg {
	background-image: url("/images/backgrounds/grid-pattern.png");
	background-position: left top;
	background-repeat: repeat;
}
.grid-gradient-bg {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 120px;
	background: url("/images/colored_theme/grid-gradient-bg.png") repeat-x left top;
	z-index: 2;
}

/* Stripe section
--------------------------------------------------- */
.stripe {
	width: 100%;
	padding: 40px 0 35px 0;
	border-top: 1px solid #e6e9e4;
	border-bottom: 1px solid #e6e9e4;
	background-color: #ebeee9;
	background: -webkit-linear-gradient(top, #e6e9e4, #ebeee9 20px);
	background: -moz-linear-gradient(top, #e6e9e4, #ebeee9 20px);
	background: -o-linear-gradient(top, #e6e9e4, #ebeee9 20px);
	background: -ms-linear-gradient(top, #e6e9e4, #ebeee9 20px);
	background: linear-gradient(to bottom, #e6e9e4, #ebeee9 20px);
}
.stripe.top {
	padding: 25px 0 30px;
	border-top: none;
	background: #f0f2ed;
	margin-top: -50px;
	margin-bottom: 50px;
}
.stripe.bottom {
	border-top-color: #c7cfc3;
	border-bottom: none;
	margin-top: 60px;
	margin-bottom: -60px;
	padding: 25px 0;
}
.stripe.white-bg {
	border-top: 1px solid #ebeee9;
	border-bottom-color: #ebeee9;
	background: #fff;
}
.stripe.white-bg.top { border-top: none; }

/* .grid-bg class should not be used with .top, .bottom or .white-bg class */
.stripe .grid-bg {
	padding: 40px 0 35px 0;
	margin: -40px 0 -35px 0;
}

.stripe.map-wrapper {
	border-top: 1px solid #fff !important;
	border-bottom: 1px solid #fff;
	background: none;
	padding: 0;
	-webkit-box-shadow: 0 0 1px #666;
	   -moz-box-shadow: 0 0 1px #666;
	        box-shadow: 0 0 1px #666;
}
.stripe.bottom.map-wrapper { border-bottom: 1px solid #ebeee9; }

/* Vector icons - Font Awesome
--------------------------------------------------- */
.fa { font-size: 14px; }

.fa-icon-list {
	list-style: none;
	margin: 0;
}
.fa-icon-list li {
	float: left;
	margin: 0 0 0 15px;
	line-height: 19px;
}
.fa-icon-list li a { font-size: 14px; line-height: 14px; }
.fa-icon-list li:first-child { margin-left: 0; }
.fa-icon-list a .fa { color: #b1b9ad; }
.fa-icon-list a:hover .fa { color: #959c92; }
#top-bar .fa-icon-list a .fa { color: #999; }
#top-bar .fa-icon-list a:hover .fa { color: #666; }

.fontawesome-icon-list .columns { margin-bottom: 10px; }
.fontawesome-icon-list .fa { margin-right: 10px; }


/* Specific styles for screens with 768 pixels wide and higher (overwrite the basic styles)
--------------------------------------------------- */
@media only screen and (min-width: 768px) {
	body { font-size: 13px; line-height: 22px; }
	p { margin-bottom: 15px; line-height: 22px; }
	li { line-height: 22px; }
	.italic { font-size: 13px; line-height: 22px; }
	
	.small-font-size, .small-font-size > * { font-size: 12px; line-height: 19px; }
	p.small-font-size { margin-bottom: 12px; }
	ul.small-font-size, ol.small-font-size { margin-bottom: 15px; }
	
	.middle-font-size, .middle-font-size > * { font-size: 15px; line-height: 24px; }
	.large-font-size, .large-font-size > * { font-size: 19px; line-height: 28px; }
	
	p.tight { line-height: 20px; }
	.middle-font-size.tight, .middle-font-size.tight > * { line-height: 20px; }
	.large-font-size.tight, .large-font-size.tight > * { line-height: 25px; }
	
	/* Blockquotes */
	blockquote:not(.testimonial), blockquote:not(.testimonial) p { font-size: 16px; line-height: 26px; }
	.ie8 blockquote, .ie8 blockquote p { font-size: 13px; line-height: 22px; }
	blockquote cite { font-size: 13px; }
}


/*-----------------------------------------------------------------------------------*/
/*	2. Header Menu
/*-----------------------------------------------------------------------------------*/

#navigation {
	float: right;
	margin-top: 30px;
}
#header.extended #navigation { margin-top: 10px; }

#primary-nav { float: right; }

#primary-nav ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
#primary-nav > ul { line-height: 50px; }

#primary-nav ul a {
	display: block;
	position: relative;
	padding: 5px 18px 0 18px;
}
#primary-nav > ul > li > a {
	font-family: "Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	color: #777 !important;
	height: 45px;
}
.ie8 #primary-nav > ul > li > a { font-family: OpenSansSemibold, Arial, "Helvetica Neue", Helvetica, sans-serif; }

#primary-nav ul li {
	position: relative;
	margin: 0;
}
#primary-nav > ul > li { float: left; }

#primary-nav > ul > li:last-child > a { padding-right: 0; }
#primary-nav ul li a:hover,
#primary-nav ul li.sfHover > a { color: #444 !important; }
#primary-nav > ul > li > a.current { color: #e8554e !important; }

#primary-nav ul ul {
	position: absolute;
	top: -999em;
	width: 170px; /* left offset of submenus need to match (see below) */
	-webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
	   -moz-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    		box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
}
#primary-nav ul ul a {
	text-transform: none;
	overflow: hidden;
}
.js #primary-nav ul ul { width: auto; min-width: 160px; }
.js #primary-nav ul ul a { white-space: nowrap; }

#primary-nav ul ul li {
	width: 100%;
	height: 37px;
	background-color: #fff;
}
#primary-nav ul ul li.divider { padding-bottom: 6px; }
#primary-nav ul ul li.divider:after {
	position: absolute;
	bottom: 0;
	left: 18px;
	right: 18px;
	height: 0;
	content: '';
	border-bottom: 1px dashed #c7cfc3;
}
#primary-nav ul ul li.divider + li { padding-top: 6px; }

#primary-nav > ul > li > ul > li:first-child { border-top: 1px solid #eaeaea; }
.ie8 #primary-nav > ul > li > ul > li:first-child { border-color: #e5e5e5; }

#primary-nav ul ul li a {
	font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 12px;
	line-height: 26px;
	height: 32px;
	color: #777 !important;
}
.touch-screen #primary-nav ul ul li a {
	font-family: "Open Sans", "Helvetica Neue", Helvetica, sans-serif; /* submenu font for tablets */
}
.ie8 #primary-nav ul ul li a { font-family: Arial, "Helvetica Neue", Helvetica, sans-serif; }

#primary-nav ul ul li a:hover,
#primary-nav ul ul li a.current:hover,
#primary-nav ul ul li.sfHover > a {
	color: #333;
	background-color: #f8f8f8;
}
#primary-nav ul ul li a.current { color: #333 !important; }

/* 3nd level */
#primary-nav ul ul ul { width: 170px; }
.js #primary-nav ul ul ul { width: auto; min-width: 160px; }

/* Make sub menus appear */
#primary-nav ul li:hover ul,
#primary-nav ul li.sfHover ul {
	left: 0;
	top: 49px; /* match top ul list item height - 1px */
}

/* Hide all subs subs (3 levels deep) */
#primary-nav ul li:hover li ul,
#primary-nav ul li.sfHover li ul,
#primary-nav ul li li:hover li ul,
#primary-nav ul li li.sfHover li ul { top: -999em; }

/* Display all subs subs (3 levels deep) */
#primary-nav ul li li:hover ul,
#primary-nav ul li li.sfHover ul,
#primary-nav ul li li li:hover ul,
#primary-nav ul li li li.sfHover ul {
	left: 170px; /* match ul width */
	top: 0;
	z-index: 2;
}
#primary-nav ul li li.divider:hover > ul,
#primary-nav ul li li.divider.sfHover > ul,
#primary-nav ul li li li.divider:hover > ul,
#primary-nav ul li li li.divider.sfHover > ul {
	top: 6px;
}

/* Submenu arrows */
#primary-nav .sf-arrows ul .sf-with-ul:after {
	position: absolute;
	top: 50%;
	right: 12px;
	width: 4px;
	height: 7px;
	margin-top: -3px;
	content: '';
	background: url("/images/icons/list-marker-arrow.png") no-repeat 0 0;
	opacity: 0.7;
}
#primary-nav .sf-arrows ul .sf-with-ul:hover:after { opacity: 1; }
#primary-nav ul ul .sf-with-ul { padding-right: 35px; }

/* Megamenu
--------------------------------------------------- */
#primary-nav > ul { position: relative; }
#primary-nav .sf-mega-parent { position: static; }
#primary-nav .sf-mega {
	position: absolute;
	left: 0;
	top: -999em;
	max-width: 1036px;
	padding: 25px 30px;
	background-color: #fff;
	border-top: 1px solid #eaeaea;
	-webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
	   -moz-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    		box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
	z-index: 2;
}
.ie8 #primary-nav .sf-mega { border-color: #e5e5e5; }
#primary-nav .sf-mega-table {
	display: table;
	width: 100%;
}
#primary-nav ul li:hover .sf-mega {
	top: 49px; /* match top ul list item height - 1px */
}
#primary-nav .sf-mega-section {
	display: table-cell;
	padding-left: 30px;
}
#primary-nav .sf-mega-section:first-child { padding-left: 0; }
#primary-nav .sf-mega ul {
	position: static;
	top: auto;
	width: auto !important;
	min-width: 0 !important;
	margin-left: -10px;
	-webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none;
}
#primary-nav .sf-mega li {
	width: auto;
	height: auto;
	background-color: transparent;
}
#primary-nav .sf-mega a {
	display: block;
	position: static;
	height: auto;
	line-height: 19px;
	padding: 5px 10px;
	overflow: visible;
	white-space: normal !important;
}
#primary-nav .sf-mega ul.arrow-list li:hover { background-color: #f8f8f8; }
#primary-nav .sf-mega ul.arrow-list a:hover { background-color: transparent; }
#primary-nav .sf-mega ul.arrow-list li { padding-left: 10px; }
#primary-nav .sf-mega ul.arrow-list a { padding-left: 11px; }
#primary-nav .sf-mega ul.arrow-list li {
	background-image: url("/images/icons/list-marker-sm.png");
	background-position: 10px 12px;
}
#primary-nav .sf-mega h4 { margin-bottom: 7px; }

/* Sticky menu
--------------------------------------------------- */
body.sticky-menu-active #header {
	position: fixed;
	top: -80px; /* match header height */
}
body.sticky-menu-active #header.extended { top: -99px; }
body.sticky-menu-active #header.top-bar-embedded { top: -118px; }
body.sticky-menu-active #logo {
	bottom: 0;
	width: 40px;
	height: 30px;
	padding-top: 3px;
	background: #f0f2ed url("/images/colored_theme/scroll-top-arrow.png") no-repeat center center;
	border: 1px solid #e6e9e4;
	border-bottom: none;
	-webkit-border-radius: 40px 40px 0 0;
	   -moz-border-radius: 40px 40px 0 0;
	        border-radius: 40px 40px 0 0;
	opacity: 0.7;
	cursor: pointer;
	-webkit-transition: opacity 0.15s ease;
	   -moz-transition: opacity 0.15s ease;
	     -o-transition: opacity 0.15s ease;
	        transition: opacity 0.15s ease;
}
body.sticky-menu-active #logo:hover { opacity: 1; }
body.sticky-menu-active #logo img { display: none; }

body.sticky-menu-active #primary-nav > ul > li > a { height: 35px; }
body.sticky-menu-active #primary-nav > ul > li.sfHover > ul,
body.sticky-menu-active #primary-nav ul li:hover .sf-mega { top: 39px; }

.ie8 body.sticky-menu-active #header { border-bottom: 1px solid #eaeaea; }
.ie8 body.sticky-menu-active #logo {
	bottom: 3px;
	background-color: transparent;
	border: none;
	filter: alpha(opacity=70);
}
.ie8 body.sticky-menu-active #logo:hover { filter: alpha(opacity=100); }

/* Responsive menu
--------------------------------------------------- */
.selectnav {
	display: none;
	float: left;
	width: 100%;
	height: 30px;
	cursor: pointer;
}

/* Secondary menu (header quick links)
--------------------------------------------------- */
#secondary-nav {
	clear: both;
	overflow: hidden;
	margin-bottom: 20px;
}
#secondary-nav .quick-links { float: right; }
.quick-links {
	list-style: none;
	margin: 0;
}
.quick-links li {
	float: left;
	font-size: 12px;
	line-height: 19px;
	margin: 0;
}
.quick-links li span {
	color: #dedede;
	padding: 0 10px;
}
.quick-links a { color: #fff; }
.quick-links a:hover { color: #ccc; }
#secondary-nav .quick-links a { color: #999; }
#secondary-nav .quick-links a:hover { color: #666; }
#secondary-nav .phone-number {
	font-size: 15px;
	line-height: 18px;
	color: #b1b9ad;
	font-weight: 600;
	padding-top: 1px;
}
.ie8 #secondary-nav .phone-number { font-family: OpenSansSemibold, Arial, "Helvetica Neue", Helvetica, sans-serif; }


/*-----------------------------------------------------------------------------------*/
/*	3. Homepage Hero
/*-----------------------------------------------------------------------------------*/

#home-hero-content {
	position: relative;
	overflow: hidden;
	height: 450px;
	background-color: #f8f9f4;
	z-index: 10;
}

#home-hero {
	position: relative;
	z-index: 1;
}

.hero-image {
	float: left;
	margin-left: 270px;
}
.hero-image img, #home-hero-content .slides img {
	width: 1220px;
	height: auto;
}

/* Full-width slider with center-aligned image */
#home-hero-content.full-width-image { height: auto; overflow: visible; }
#home-hero-content.full-width-image .grid-gradient-bg { display: none; }
#home-hero-content.full-width-image #home-hero { width: 100%; max-width: 1680px; }
#home-hero-content.full-width-image #home-hero > .columns { width: 100%; margin: 0; }
#home-hero-content.full-width-image .hero-image { float: none; margin-left: 0; }
#home-hero-content.full-width-image .hero-image img,
#home-hero-content.full-width-image .slides img { width: auto; max-width: 100%; }

#home-hero-content.full-width-image .slides .container { /* container for .flex-caption */
	position: absolute;
	left: 0; top: 0; right: 0; bottom: 0;
}
#home-hero-content.full-width-image .slides .columns {
	position: absolute;	
	width: 100%;
	height: 100%;
}

/* Hero navigation
--------------------------------------------------- */
#home-hero-nav {
	position: absolute;
	width: 100%;
	top: 0;
	z-index: 30;
}
#home-hero-nav .columns { position: relative; top: 490px; }

#home-hero-content.full-width-image + #home-hero-nav { display: none; }

.slider-menu {
	position: absolute;
	left: 400px;
	bottom: 25px;
	list-style: none;
	margin: 0 50px 0 0;
	background-color: #f8f9f4;
	border: 1px solid #fff;
	-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	   -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.slider-menu li * { margin-bottom: 0; }
.slider-menu li {
	float: left;
	margin-bottom: 0;
}
.slider-menu li a {
	position: relative;
	display: block;
	padding: 8px 15px 9px;
	background-color: #f8f9f4;
	border-left: 1px solid #b1b9ad;
	-webkit-transition-duration: 0.3s;
	   -moz-transition-duration: 0.3s;
	     -o-transition-duration: 0.3s;
	        transition-duration: 0.3s;
}
.slider-menu li a, .slider-menu li a * { color: #b1b9ad; }
.slider-menu li:first-child a { border-left: none; }
.slider-menu li.active a {
	background-color: #B20000;
	border-left-color: #B20000;
	cursor: default;
}
.slider-menu li.active + li a { border-left-color: #959c92; }
.slider-menu li.active a, .slider-menu li.active a *,
.slider-menu li.active a:hover, .slider-menu li.active a:hover * { color: #f8f9f4; }
.slider-menu li a:hover, .slider-menu li a:hover * { color: #777; }
.slider-menu h6 {
	font: 17px/22px "Roboto Condensed", "Franklin Gothic Medium", Tahoma, sans-serif;
	font-weight: 300;
}

/* Hero navigation - thumbnails
--------------------------------------------------- */
.slider-menu.thumbnails {
	margin-right: 20px;
	background: none;
	border: none;
	-webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none;
}
.slider-menu.thumbnails img { max-width: 100%; height: auto; }
.slider-menu.thumbnails li {
	margin-left: 20px;
}
.slider-menu.thumbnails li:first-child { margin-left: 0; }
.slider-menu.thumbnails li a {
	position: relative;
	background: none;
	border: 2px solid #f8f9f4 !important;
	padding: 0;
	-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	   -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.slider-menu.thumbnails li.active a { border-color: #e8554e !important; }

/* Homepage slider caption
   (for the version of slider with right-aligned image)
--------------------------------------------------- */
#home-hero-nav .slider-caption {
	position: absolute;
	left: 430px;
	bottom: 0;
	width: 100%;
	max-width: 630px;
}
.slider-caption > div {
	position: absolute;
	display: none;
}
#home-hero-nav .slider-caption > div {
	bottom: 0;
	padding: 10px 20px 12px;
	background-color: #e8554e;
	background: rgba(232, 85, 78, 0.5);
}
.slider-caption h2, .slider-caption h3, .slider-caption h4, .slider-caption h5 { margin-bottom: 8px; }
.slider-caption h2 { line-height: 37px; }
.slider-caption h3 {
	font: 26px/31px "Roboto Condensed", "Franklin Gothic Medium", Tahoma, sans-serif;
	font-weight: 300;
}
.slider-caption *:last-child { margin-bottom: 0; }
.slider-caption .middle-font-size { font-size: 15px; line-height: 22px; }
.slider-caption .large-font-size { font-size: 17px; line-height: 25px; }

.ie8 #home-hero-nav .slider-caption > div {
	background: transparent;
	zoom: 1;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#80cc2817,endColorstr=#80cc2817); 
}

/* Homepage slider caption inside the "intro" section
--------------------------------------------------- */
.intro-content .slider-caption { position: relative; }
.intro-content .slider-caption > div { padding-right: 20px; }
.intro-content .number-box {
	float: left;
	font-size: 22px;
	line-height: 28px;
	color: #e8554e;
}
.intro-content .number-box + * {
	margin-left: 25px;
	padding-left: 15px;
	border-left: 1px solid #e8554e;
}


/*-----------------------------------------------------------------------------------*/
/*	4. Homepage Intro
/*-----------------------------------------------------------------------------------*/

#home-intro {
	position: absolute;
	width: 100%;
	top: 0;
	z-index: 20;
}
#home-intro .columns { position: relative; }

.intro-wrapper {
	position: absolute;
	left: -140px;
	top: -70px;
	width: 560px;
	height: 560px;
	background: url("/images/colored_theme/intro-shadow.png") no-repeat;
}
.intro-wrapper:before {
	position: absolute;
	display: block;
	left: 0; top: 0; width: 100%; height: 100%;
	content: '';
	background: url("/images/colored_theme/intro-bg.png") no-repeat;
	z-index: 1;
}
.intro-content {
	position: relative;
	padding: 110px 100px 0 140px;
	z-index: 2;
}

/* Brief navigation
--------------------------------------------------- */
.brief-nav { list-style: none; }
.brief-nav li {
	float: left;
	background: url("/images/icons/slash.png") no-repeat 0 3px;
	padding: 0 9px 0 15px;
	margin: 0;
}
.brief-nav li:first-child {
	background: none;
	padding-left: 0;
}
.brief-nav li:last-child { padding-right: 0; }

/* Video preview
--------------------------------------------------- */
.video-preview a[data-rel=prettyPhoto] {
	position: relative;
	display: block;
}
.video-preview img { max-width: 100%; height: auto; }
.video-preview .overlay {
	position: absolute;
	left: 0; top: 0; right: 0; bottom: 0;
	z-index: 2;
	background-color: #959c92;
	background: rgba(149, 156, 146, 0.2);
	-webkit-transition: background-color 0.2s ease-out;
	   -moz-transition: background-color 0.2s ease-out;
	     -o-transition: background-color 0.2s ease-out;
	        transition: background-color 0.2s ease-out;
}
.video-preview .play-icon {
	position: absolute;
	left: 0; top: 0; width: 100%; height: 100%;
	background: url("/images/icons/play-icon-large.png") no-repeat 50% 50%;
	opacity: 0.7;
	-webkit-transition: opacity 0.2s ease-out;
	   -moz-transition: opacity 0.2s ease-out;
	     -o-transition: opacity 0.2s ease-out;
	        transition: opacity 0.2s ease-out;
}
.no-touch-screen .video-preview a[data-rel=prettyPhoto]:hover .overlay { background: rgba(149, 156, 146, 0.4); }
.no-touch-screen .video-preview a[data-rel=prettyPhoto]:hover .play-icon-large { opacity: 0.92; }

.video-preview .html5-video,
.video-preview iframe { display: none; }

/* Preview (video/image type) inside the "intro" section */
.intro-content .preview-block > [class^="thumbnail"] {
	float: left;
	margin: 0 15px 0 20px;
}
.intro-content .video-preview .overlay {
	background-color: #959c92;
	background: rgba(149, 156, 146, 0);
}
.intro-content .video-preview .play-icon {
	opacity: 0;
	background-image: url("/images/icons/play-icon.png");
}
.intro-content .preview-info h6 {
	font: 17px/22px "Roboto Condensed", "Franklin Gothic Medium", Tahoma, sans-serif;
	font-weight: 300;
}
.no-touch-screen .intro-content .video-preview a[data-rel=prettyPhoto]:hover .overlay { background: rgba(149, 156, 146, 0.9); }
.no-touch-screen .intro-content .video-preview a[data-rel=prettyPhoto]:hover .play-icon { opacity: 1; }
.intro-content .preview-block .thumbnail-colored { border-width: 1px; }

/* For Internet Explorer 8 */
.ie8 .video-preview .overlay {
	background: transparent;
	zoom: 1;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#33959c92,endColorstr=#33959c92);
}
.ie8 .video-preview a[data-rel=prettyPhoto]:hover .overlay {
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#66959c92,endColorstr=#66959c92);
}
.ie8 .intro-content .video-preview .overlay {
	background: transparent;
	zoom: 1;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00959c92,endColorstr=#00959c92);
}
.ie8 .intro-content .video-preview a[data-rel=prettyPhoto]:hover .overlay {
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#df959c92,endColorstr=#df959c92);
}
.ie8 .intro-content .video-preview .play-icon { display: none; }
.ie8 .intro-content .video-preview a[data-rel=prettyPhoto]:hover .play-icon { display: block; }


/*-----------------------------------------------------------------------------------*/
/*	5. Homepage Featured Content
/*-----------------------------------------------------------------------------------*/

#home-featured-content {
	background-color: #ebeee9;
	background: -webkit-linear-gradient(bottom, #e6e9e4, #ebeee9 30px);
	background: -moz-linear-gradient(bottom, #e6e9e4, #ebeee9 30px);
	background: -o-linear-gradient(bottom, #e6e9e4, #ebeee9 30px);
	background: -ms-linear-gradient(bottom, #e6e9e4, #ebeee9 30px);
	background: linear-gradient(to top, #e6e9e4, #ebeee9 30px);
}
#home-featured-content .grid-bg { padding: 70px 0 25px 0; }
#home-featured-content.tight .grid-bg { padding-top: 50px; }

#home-featured-content .feature-boxes.left-icon-box { margin-top: 15px; }

/*-----------------------------------------------------------------------------------*/
/*	6. Page Title, Secondary Navigation & Headline
/*-----------------------------------------------------------------------------------*/

#page-title {
	border-bottom: 1px solid #c7cfc3;
	background-color: #ebeee9;
	background: -webkit-linear-gradient(bottom, #e6e9e4, #ebeee9 30px);
	background: -moz-linear-gradient(bottom, #e6e9e4, #ebeee9 30px);
	background: -o-linear-gradient(bottom, #e6e9e4, #ebeee9 30px);
	background: -ms-linear-gradient(bottom, #e6e9e4, #ebeee9 30px);
	background: linear-gradient(to top, #e6e9e4, #ebeee9 30px);
}
#page-title h1 { margin-bottom: 0; }
#page-title .grid-bg {
	padding: 25px 0 33px 0;
	background-position: 0 -10px;
}

/* Custom image header
--------------------------------------------------- */
#page-title.custom-bg {
	position: relative;
	height: 450px;
	border-bottom: none;
	background-color: #f8f9f4;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}
@media only screen and (min-width: 1200px) {
	#page-title.custom-bg { background-size: auto; }
}

/* Header background image for "Vision & Values" example page (page-custom-header.html) */
#page-title.custom-bg.values { background-image: url("/images/banners/header-values.jpg"); }

#page-title.custom-bg .container {
	position: absolute;
	left: 0; top: 0; right: 0; bottom: 0;
}
#page-title.custom-bg .columns {
	position: absolute;	
	width: 100%;
	height: 100%;
}
#page-title.custom-bg h1 {
	position: absolute;
	left: 0;
	bottom: 35px;
	color: #fff;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}

/* Breadcrumb navigation
--------------------------------------------------- */
.breadcrumb-nav {
	float: right;
	list-style: none;
	margin: 14px 0 0 20px;
}
.breadcrumb-nav li {
	float: left;
	background: url("/images/colored_theme/link-arrow-small-colored-1.png") no-repeat 0 9px;
	padding: 0 9px 0 12px;
	margin: 0;
	line-height: 22px !important;
}
.breadcrumb-nav li:first-child {
	background: none;
	padding-left: 0;
}
.breadcrumb-nav li:last-child { padding-right: 0; }
.breadcrumb-nav a.link-sm {
	background: none;
	padding: 0 0 3px;
}
.breadcrumb-nav a.link-sm:hover { background: none; }
.breadcrumb-nav a.link-sm span { margin-right: 0; }
.breadcrumb-nav .italic { font-size: 13px; line-height: 22px; }

/* Subheader secondary menu
--------------------------------------------------- */
#subheader-bar {
	border-bottom: 1px solid #f0f2ed;
	background-color: #fff;
	padding: 10px 0;
	margin-bottom: -10px;
}

#subheader-bar .sub-nav {
	list-style: none;
	margin: 0;
}
#subheader-bar .sub-nav li {
	float: left;
	margin: 0 25px 0 0;
}
#subheader-bar .sub-nav a {
	font-size: 14px;
	line-height: 23px;
	color: #959c92;
}
#subheader-bar .sub-nav li:last-child { margin-right: 0; }
#subheader-bar .sub-nav li a:hover { color: #444; }
#subheader-bar .sub-nav li.current a { color: #e8554e; }

#subheader-bar .breadcrumb-nav {
	float: left;
	margin: 0;
}

/* Headline
--------------------------------------------------- */
.headline { position: relative; }
.headline .four.columns div,
.headline .one-third.columns div {
	position: absolute;
	right: 10px;
	bottom: 10px;
	text-align: right;
}
.headline span {
	display: block;
	line-height: 17px;
}


/*-----------------------------------------------------------------------------------*/
/*	7. FlexSlider
/*-----------------------------------------------------------------------------------*/

/* Browser Resets
--------------------------------------------------- */
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus { outline: none; }
.flexslider .slides,
.flex-control-nav,
.flex-direction-nav { margin: 0; padding: 0; list-style: none; }
.flex-control-nav li,
.flex-direction-nav li { margin: 0; }

/* Necessary Styles
--------------------------------------------------- */
.flexslider { margin: 0; padding: 0; position: relative;  zoom: 1; }

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides > li { position: relative;  display: none; -webkit-backface-visibility: hidden; }
.flexslider .slides img { width: 100%; display: block; }
.flex-pauseplay span { text-transform: capitalize; }

/* Clearfix for the .slides element */
.flexslider .slides:after {content: "."; display: block; clear: both; visibility: hidden; line-height: 0; height: 0;} 
html[xmlns] .flexslider .slides {display: block;} 
* html .flexslider .slides {height: 1%;}

/* No JavaScript Fallback */
.no-js .flexslider .slides > li:first-child {display: block;}

/* Default Styles
--------------------------------------------------- */
.flex-viewport {max-height: 2000px; -webkit-transition: all 1s ease; -moz-transition: all 1s ease; transition: all 1s ease;}
.flexslider .loading .flex-viewport {max-height: 300px;}
.ie8 .flexslider .slides {zoom: 1;}
.flexslider .slides > li { margin-bottom: 0; }

/* Caption
--------------------------------------------------- */
.flex-caption {
	display: block;
	position: absolute;
	width: 30%;
	left: 0;
	bottom: 20px;
	padding: 17px 20px 20px;
	background-color: #fff;
	background: rgba(255, 255, 255, 0.9);
}
.flex-caption.no-bg {
	padding: 0;
	background: none;
}
.ie8 .flex-caption {
	background: transparent;
	zoom: 1;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#b3000000,endColorstr=#b3000000); 
}

/* Direction Nav
--------------------------------------------------- */
#home-slider .flex-direction-nav { display: none; }
#home-hero-content.full-width-image .flex-direction-nav { display: block; }
.flex-direction-nav a {
	display: block;
	position: absolute;
	z-index: 20;
	width: 36px;
	height: 72px;
	top: 50%;
	margin: -36px 0 0;
	background-color: #000;
	background-color: rgba(0, 0, 0, 0.3);
	background-image: url("/images/icons/slider-direction-nav.png");
	background-repeat: no-repeat;
	cursor: pointer;
	text-indent: -9999px;
}
.flex-direction-nav .flex-prev {
	left: 0; 
	background-position: 10px -152px;
	-webkit-border-radius: 0 36px 36px 0;
	   -moz-border-radius: 0 36px 36px 0;
	        border-radius: 0 36px 36px 0;
}
.flex-direction-nav .flex-next {
	right: 0; 
	background-position: -43px -152px;
	-webkit-border-radius: 36px 0 0 36px;
	   -moz-border-radius: 36px 0 0 36px;
	        border-radius: 36px 0 0 36px;
}
.flex-direction-nav .flex-prev:hover, .flex-direction-nav .flex-next:hover {
	background-color: rgba(232, 85, 78, 0.5);
}
.flex-direction-nav .flex-disabled, .flex-direction-nav .flex-disabled:hover {
	background-color: #000;
	opacity: 0.2;
	cursor: default;
}
.ie8 .flex-direction-nav a { filter: alpha(opacity=40); }
.ie8 .flex-direction-nav .flex-prev:hover, .ie8 .flex-direction-nav .flex-next:hover {
	background-color: #e8554e;
	filter: alpha(opacity=60);
}
.ie8 .flex-direction-nav .flex-disabled, .ie8 .flex-direction-nav .flex-disabled:hover { filter: alpha(opacity=20); }

/* Homepage slider with right side navigation: specific styles for screens with 768 pixels wide and higher */
@media only screen and (min-width: 768px) {
	#home-slider.right-side-nav .flex-direction-nav { display: block; }
	#home-slider.right-side-nav .flex-direction-nav a {
		width: 40px;
		height: 54px;
		left: 0;
		margin: 0;
		background-color: #fff;
		background-color: rgba(255, 255, 255, 0.4);
		opacity: 0;
		-webkit-border-radius: 0; -moz-border-radius: 0; border-radius: 0;
	}
	#home-header:hover #home-slider.right-side-nav .flex-direction-nav a,
	.touch-screen #home-slider.right-side-nav .flex-direction-nav a { opacity: 1; }
	
	#home-slider.right-side-nav .flex-direction-nav .flex-prev { top: 170px; background-position: 15px 18px; }
	#home-slider.right-side-nav .flex-direction-nav .flex-next { top: 225px; background-position: -45px 18px; }
	#home-slider.right-side-nav .flex-direction-nav .flex-prev:hover,
	#home-slider.right-side-nav .flex-direction-nav .flex-next:hover {
		background-color: #fff;
		background-color: rgba(255, 255, 255, 0.55);
	}
	.ie8 #home-slider.right-side-nav .flex-direction-nav a { filter: alpha(opacity=60); }
	.ie8 #home-slider.right-side-nav .flex-direction-nav .flex-prev { background-position: 15px -42px; }
	.ie8 #home-slider.right-side-nav .flex-direction-nav .flex-next { background-position: -45px -42px; }
	.ie8 #home-slider.right-side-nav .flex-direction-nav .flex-prev:hover,
	.ie8 #home-slider.right-side-nav .flex-direction-nav .flex-next:hover {
		filter: alpha(opacity=80);
	}
}

/* Control Nav
--------------------------------------------------- */
.flex-control-nav {
	position: absolute;
	width: 100%;
	bottom: -25px;
	line-height: 14px;
	text-align: center;
}
.flex-control-nav li {
	display: inline-block;
	margin: 0 0 0 10px;
	zoom: 1;
}
.flex-control-nav li:first-child { margin: 0; }
.flex-control-paging li a {
	display: block;
	cursor: pointer;
}
.flex-control-paging li a span {
	display: block;
	width: 10px;
	height: 10px;
	line-height: 10px;
	background-color: transparent;
	border: 2px solid #c7cfc3;
	text-indent: -9999px;
	-webkit-border-radius: 14px;
	   -moz-border-radius: 14px;
	        border-radius: 14px;
	-webkit-transition: border-color 0.15s ease;
	   -moz-transition: border-color 0.15s ease;
	     -o-transition: border-color 0.15s ease;
	        transition: border-color 0.15s ease;
}
.flex-control-paging li a:hover span { border-color: #e8554e; }
.flex-control-paging li a.flex-active { cursor: default; }
.flex-control-paging li a.flex-active span {
	background-color: #e8554e;
	border-color: #e8554e;
}


/*-----------------------------------------------------------------------------------*/
/*	8. Feature Boxes
/*-----------------------------------------------------------------------------------*/

.feature-boxes article { margin-top: 30px; }
.feature-boxes .row:first-child article { margin-top: 0; }

/* medium column space */
.feature-boxes.medium-col-space { margin: 0 -15px; }
.feature-boxes.medium-col-space .columns { margin-left: 15px; margin-right: 15px; }
.feature-boxes.medium-col-space article { margin-left: 10px; margin-right: 10px; }

/* small column space */
.feature-boxes.small-col-space { margin: 0 -12px; }
.feature-boxes.small-col-space .columns { margin-left: 13px; margin-right: 13px; }
.feature-boxes.small-col-space article { margin-left: 9px; margin-right: 9px; }

.feature-boxes h3 {
	font-size: 21px;
	line-height: 28px;
	color: #333;
}

/* Box with left icon
--------------------------------------------------- */

/* Style 1 - Default */
.feature-boxes.left-icon-box header {
	height: 48px;
	margin-left: 63px;
}
.feature-boxes.left-icon-box header:before {
	content: '';
	display: inline-block;
	height: 100%; 
	vertical-align: middle;
}
.feature-boxes.left-icon-box header h3 {
	display: inline-block;
	line-height: 26px;
	vertical-align: middle;
	margin-bottom: 3px;
}
.feature-boxes.left-icon-box header + p {
	margin-top: 8px;
	clear: left;
}
.feature-boxes.left-icon-box header + p:last-child { margin-bottom: 0; }
.feature-boxes.left-icon-box img { float: left; } /* bitmap icons - <img> tag */
.feature-boxes.left-icon-box article > .fa { /* vector icons - Font Awesome */
	float: left;
	font-size: 43px;
	line-height: 43px;
	color: #666;
	margin-top: 5px;
}
.feature-boxes.left-icon-box article div h3 { margin-bottom: 5px; }
.feature-boxes.left-icon-box article div h4,
.feature-boxes.left-icon-box article div h5,
.feature-boxes.left-icon-box article div h6 { margin-bottom: 2px; }

/* Style 2 - Small Icon */
.feature-boxes.left-icon-box.small-icon article { margin-top: 25px; }
.feature-boxes.left-icon-box.small-icon .row:first-child article { margin-top: 0; }
.feature-boxes.left-icon-box.small-icon article > span {
	display: block;
	float: left;
	width: 35px;
	height: 35px;
	line-height: 35px;
	background-color: #666;
	-webkit-border-radius: 35px;
	   -moz-border-radius: 35px;
	        border-radius: 35px;
	margin-top: 5px;
    text-align: center;
}
.feature-boxes.left-icon-box.small-icon span .fa {
	font-size: 14px;
	line-height: 14px;
	color: #fff;
}
.feature-boxes.left-icon-box.small-icon article > div { margin-left: 50px; }
.feature-boxes.left-icon-box.small-icon article p { margin-bottom: 0; }

/* Style 2 - Small Icon: number type */
.feature-boxes.left-icon-box.small-icon.number-type article { margin-top: 15px; }
.feature-boxes.left-icon-box.small-icon.number-type article:first-child { margin-top: 0; }
.feature-boxes.left-icon-box.small-icon.number-type span {
	font-size: 20px;
	font-weight: 600;
	color: #fff;
}
.feature-boxes.left-icon-box.small-icon.number-type h4,
.feature-boxes.left-icon-box.small-icon.number-type h5,
.feature-boxes.left-icon-box.small-icon.number-type h6 { margin-bottom: 5px; }
.feature-boxes.left-icon-box.small-icon.number-type article > div > *:last-child { margin-bottom: 0; }
.ie8 .feature-boxes.left-icon-box.small-icon.number-type span {
	font-family: OpenSansSemibold, Arial, "Helvetica Neue", Helvetica, sans-serif;
}

/* Style 3 - Medium Icon */
.feature-boxes.left-icon-box.medium-icon article p { margin-bottom: 0; }
.feature-boxes.left-icon-box.medium-icon article > .fa {
	font-size: 35px;
	line-height: 35px;
}
.feature-boxes.left-icon-box.medium-icon article > div { margin-left: 63px; }
.feature-boxes.left-icon-box.medium-icon article > .fa + div { margin-left: 55px; }

/* Style 4 - Big Icon */
.feature-boxes.left-icon-box.big-icon article > div { margin-left: 115px; }

/* Box with top icon
--------------------------------------------------- */
.feature-boxes.top-icon-box article { margin-top: 40px; }
.feature-boxes.top-icon-box img { max-width: 100%; height: auto; }
.feature-boxes.top-icon-box img[data-scale=no] { display: inline-block; margin-bottom: -6px; }
.feature-boxes.top-icon-box h4, .feature-boxes.top-icon-box h5 { margin: 12px 0 7px; }
.feature-boxes.top-icon-box p { margin-bottom: 12px; }
.feature-boxes.top-icon-box.short-description p { margin: 7px 0 5px; }

/* Center aligned */
.feature-boxes.top-icon-box.centered article { text-align: center; }
.feature-boxes.top-icon-box.centered article img { display: inline-block; margin-bottom: -6px; }
.feature-boxes.top-icon-box.centered article p { margin: 12px 10px 0; }


/*-----------------------------------------------------------------------------------*/
/*	9. Portfolio, Projects Carousel & Gallery
/*-----------------------------------------------------------------------------------*/

#portfolio-wrapper.large-col-space { margin: 0 -20px; } /* large column space */
#portfolio-wrapper.large-col-space .columns { margin-left: 20px; margin-right: 20px; }
#portfolio-wrapper.medium-col-space { margin: 0 -15px; } /* medium column space */
#portfolio-wrapper.medium-col-space .columns { margin-left: 15px; margin-right: 15px; }
#portfolio-wrapper.small-col-space { margin: 0 -6px; } /* small column space */
#portfolio-wrapper.small-col-space .columns { margin-left: 11px; margin-right: 11px; }

#portfolio-wrapper .portfolio-item-preview { margin-bottom: 40px; }

#portfolio-wrapper article,
#projects-carousel article {
	padding-bottom: 10px;
	background-color: #fff;
	-webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
	   -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
	        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}
#portfolio-wrapper.large-col-space article { margin: 0 10px; } /* large column space */
#portfolio-wrapper.medium-col-space article { margin: 0 10px; } /* medium column space */
#portfolio-wrapper.small-col-space article { margin: 0 5px; padding-bottom: 8px; } /* small column space */

/* Projects Carousel
--------------------------------------------------- */
#projects-carousel li.portfolio-item-preview {
	display: none; /* hide the slides before the JS is loaded. Avoids image jumping */
	float: left;
	padding-right: 20px;
	margin-bottom: 0;
}
#projects-carousel.two-slides li.portfolio-item-preview {
	width: 250px; /* 2 visible slides in carousel - only for pages with full-width layout inside a section with 1/2 page width */
}
#projects-carousel.three-slides li.portfolio-item-preview {
	width: 340px; /* 3 visible slides in carousel */
}
#projects-carousel.four-slides li.portfolio-item-preview {
	width: 250px; /* 4 visible slides in carousel */
}

/* layout with sidebar */
#main-content.page-left-col #projects-carousel.three-slides li.portfolio-item-preview { width: 240px; }
#main-content.page-left-col #projects-carousel.four-slides li.portfolio-item-preview { width: 175px; }
#main-content.page-right-col #projects-carousel.three-slides li.portfolio-item-preview { width: 260px; }
#main-content.page-right-col #projects-carousel.four-slides li.portfolio-item-preview { width: 190px; }

/* show only the first slide before the JS is loaded */
#projects-carousel li.portfolio-item-preview:first-child,
#projects-carousel.initiated li,
.no-js #projects-carousel li.portfolio-item-preview { display: block; }

#projects-carousel article { margin: 4px 5px 6px; }

/* Portfolio Item Description
--------------------------------------------------- */
.portfolio-item-preview .item-description {
	position: relative;
	height: 50px;
	padding: 12px 20px 0;
	overflow: hidden;
}
.portfolio-item-preview .item-description:before {
	position: absolute;
	display: block;
	left: 0; top: 0; width: 100%; height: 3px;
	content: '';
	background-color: #c7cfc3;
	background-image: -webkit-linear-gradient(left, #e8554e, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), #e8554e);
	background-image: -moz-linear-gradient(left, #e8554e, rgba(255, 255, 255, 0), #e8554e);
	background-image: -o-linear-gradient(left, #e8554e, rgba(255, 255, 255, 0), #e8554e);
	background-image: -ms-linear-gradient(left, #e8554e, rgba(255, 255, 255, 0), #e8554e);
}
.portfolio-item-preview .item-description:after {
	position: absolute;
	display: block;
	left: 0; bottom: 0; width: 100%; height: 7px;
	content: '';
	background: -webkit-linear-gradient(bottom, #ffffff, rgba(255, 255, 255, 0));
	background: -moz-linear-gradient(bottom, #ffffff, rgba(255, 255, 255, 0));
	background: -o-linear-gradient(bottom, #ffffff, rgba(255, 255, 255, 0));
	background: -ms-linear-gradient(bottom, #ffffff, rgba(255, 255, 255, 0));
	background: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
}
#portfolio-wrapper.small-col-space .item-description, /* small column space */
#projects-carousel.two-slides .item-description, /* 2 visible slides in carousel - full-width layout with 1/2 page width */
#projects-carousel.four-slides .item-description, /* 4 visible slides in carousel */
#main-content #projects-carousel.three-slides .item-description { /* 3 visible slides in carousel - layout with sidebar */
	height: 46px; padding-top: 10px;
}

.portfolio-item-preview .item-description h3 {
	font-size: 21px;
	line-height: 28px;
	color: #333;
	margin-bottom: 10px;
}
.portfolio-item-preview .item-description h4,
.portfolio-item-preview .item-description h5 { margin-bottom: 3px; }
.portfolio-item-preview .item-description h6 {
	line-height: 19px;
	margin-bottom: 0;
}
.portfolio-item-preview .item-description p { margin-bottom: 0; }

/* Long description */
#portfolio-wrapper.long-description .item-description {
	height: 115px;
	padding-top: 15px;
}
#portfolio-wrapper.long-description .item-description:after { height: 20px; }
.ie #portfolio-wrapper.long-description .item-description {
	height: 100px;
	margin-bottom: 15px;
}
#portfolio-wrapper.long-description h3,
#portfolio-wrapper.long-description h4 { margin-bottom: 7px; }
#portfolio-wrapper.long-description h4 {
	font-size: 17px;
	line-height: 25px;
}

/* Short description */
#portfolio-wrapper.short-description .item-description {
	height: 26px;
	padding-top: 10px;
}
#projects-carousel.short-description .item-description {
	height: 26px !important;
	padding-top: 10px !important;
}
#portfolio-wrapper.short-description .item-description:after,
#projects-carousel.short-description .item-description:after { display: none; }
#portfolio-wrapper.short-description h6,
#projects-carousel.short-description h6 { line-height: 22px; }

/* Intro tags */
.portfolio-item-preview .item-description .intro-tags {
	font-size: 12px;
	line-height: 19px;
	margin-bottom: 3px;
}
.portfolio-item-preview .item-description .intro-tags > span { display: inline-block; }
.portfolio-item-preview .item-description .intro-tags > span > span { padding: 0 5px 0 7px; }

/* Extra tags */
.portfolio-item-preview .extra-tags { padding: 0 20px 10px; }
.portfolio-item-preview .extra-tags > span { display: inline-block; }
.portfolio-item-preview .extra-tags > span > span { padding: 0 5px 0 7px; }
.portfolio-item-preview .extra-tags .price {
	font-size: 14px;
	color: #e8554e;
}
.portfolio-item-preview .extra-tags span + .details { margin-left: 10px; }

/* No separator line */
#portfolio-wrapper.no-sep .item-description { padding-top: 9px; }
#portfolio-wrapper.no-sep.small-col-space .item-description,
#portfolio-wrapper.no-sep.short-description .item-description { padding-top: 7px; }
#portfolio-wrapper.no-sep.long-description .item-description { padding-top: 12px; }
#portfolio-wrapper.no-sep .item-description:before { display: none; }

/* No background description */
#portfolio-wrapper.no-bg .portfolio-item-preview { margin-bottom: 35px; }
#portfolio-wrapper.no-bg.small-col-space .portfolio-item-preview { margin-bottom: 30px; }
#portfolio-wrapper.no-bg article {
	padding-bottom: 0;
	background: none;
	-webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none;
}
#portfolio-wrapper.no-bg article:hover {
	-webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none;
}
#portfolio-wrapper.no-bg .portfolio-item-preview .item-description {
	height: auto;
	padding: 12px 0 0 !important;
	overflow: visible;
}
#portfolio-wrapper.no-bg.no-sep .portfolio-item-preview .item-description { padding-top: 9px !important; }
#portfolio-wrapper.no-bg .item-description:after { display: none; }
#portfolio-wrapper.no-bg .extra-tags { padding: 0 !important; }
#portfolio-wrapper.no-bg .extra-tags > span { display: inline-block !important; }
#portfolio-wrapper.no-bg .extra-tags span + .details { margin-left: 10px !important; }
.ie #portfolio-wrapper.no-bg.long-description .item-description { margin-bottom: 0; }

/* One-column layout
--------------------------------------------------- */
#portfolio-wrapper.one-col .portfolio-item-preview {
	margin: 0 10px 35px;
	padding-top: 35px;
	border-top: 1px dotted #c7cfc3;
}
#portfolio-wrapper.one-col .portfolio-item-preview:first-child { padding-top: 0; border-top: none; }
#portfolio-wrapper.one-col .item-description {
	height: auto;
	margin: 0 0 0 15px;
	padding: 0 !important;
	overflow: visible;
}
#portfolio-wrapper.one-col .item-description:before,
#portfolio-wrapper.one-col .item-description:after { display: none; }
#portfolio-wrapper.one-col .extra-tags { padding: 0 !important; margin-left: 15px; }
#portfolio-wrapper.one-col .extra-tags > span { display: inline-block !important; }
#portfolio-wrapper.one-col .extra-tags span + .details { margin-left: 10px !important; }
.ie #portfolio-wrapper.one-col .item-description { border: none; margin-bottom: 0; }

/* Portfolio Filter
--------------------------------------------------- */
#portfolio-filter {
	list-style: none;
	margin: 0;
}
#portfolio-filter li {
	float: left;
	background: url("/images/icons/separator-dot.png") no-repeat 0 9px;
	padding: 0 12px 0 14px;
	margin: 0;
	line-height: 22px !important;
}
#portfolio-filter li:first-child {
	background: none;
	padding-left: 0;
}
#portfolio-filter li:last-child { padding-right: 0; }
#portfolio-filter li a.current { color: #e8554e; }
#portfolio-filter a.link-sm {
	background: none;
	padding: 0 0 3px;
}
#portfolio-filter a.link-sm span { margin-right: 0; }

/* Gallery
--------------------------------------------------- */
.gallery-item {
	margin-bottom: 20px !important;
	overflow: hidden;
}
.gallery-item .item-label {
	display: inline-block;
	position: absolute;
	left: 0px; bottom: 0px;
	background-color: #000;
	background: rgba(0, 0, 0, 0.25);
	padding: 3px 10px;
	color: #fff;
	z-index: 12;
	cursor: default;
}
.gallery-item .image-overlay + .item-label { cursor: pointer; }
.ie8 .gallery-item .item-label {
	background: transparent;
	zoom: 1;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#40000000,endColorstr=#40000000); 
}
.gallery-wrapper.masonry .s1 .item-picture { width: 340px; height: 239px; }
.gallery-wrapper.masonry .s2 .item-picture { width: 340px; height: 498px; }
.gallery-wrapper.masonry .s4 .item-picture { width: 700px; height: 498px; }
.gallery-wrapper.masonry img {
	position: absolute;
	left: 0;
	top: 0;
	max-width: 101%;
}
.gallery-wrapper.masonry .item-picture[data-type=text] div { padding: 25px 30px; }


/*-----------------------------------------------------------------------------------*/
/*	10. Image and Preview Image: Overlay & Border
/*-----------------------------------------------------------------------------------*/

.item-picture { position: relative; }
.home-project-preview img { height: 194px; }
.item-picture img { max-width: 100%;}
.item-picture iframe,
.item-picture .html5-video { display: none; }

/* Image Overlay
--------------------------------------------------- */
.image-overlay {
	position: absolute;
	left: 0; top: 0; right: 0; bottom: 0;
	z-index: 10;
	background-color: #000;
	background: rgba(0, 0, 0, 0);
	cursor: pointer;
	-webkit-transition: background-color 0.2s ease-out;
	   -moz-transition: background-color 0.2s ease-out;
	     -o-transition: background-color 0.2s ease-out;
	        transition: background-color 0.2s ease-out;
}
.image-overlay span {
	position: absolute;
	left: 0; top: 0; width: 100%; height: 100%;
	background-repeat: no-repeat;
	background-position: 50% 60%;
	opacity: 0;
	-webkit-transition: all 0.15s ease-out;
	   -moz-transition: all 0.15s ease-out;
	     -o-transition: all 0.15s ease-out;
	        transition: all 0.15s ease-out;
}
.image-overlay .link { background-image: url("/images/icons/overlay-icon-link.png"); }
.image-overlay .zoom { background-image: url("/images/icons/overlay-icon-zoom.png"); }
.image-overlay .video { background-image: url("/images/icons/overlay-icon-video.png"); }

.item-picture:hover .image-overlay { background: rgba(0, 0, 0, 0.35); }
.item-picture:hover .image-overlay span {
	background-position: 50% 50%;
	opacity: 1;
}
.ie8 .image-overlay, .ie8 .image-overlay span { filter: alpha(opacity=0); }
.ie8 .item-picture:hover .image-overlay { filter: alpha(opacity=50); }
.ie8 .item-picture:hover .image-overlay span { filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00ffffff,endColorstr=#00ffffff); }

/* Image Overlay: Touch-screen devices
--------------------------------------------------- */
.touch-screen .item-picture .image-overlay span {
	display: none; 
	background-position: 50% 50%;
}
.touch-screen .item-picture:hover .image-overlay { background: rgba(0, 0, 0, 0); }
.touch-screen .flexslider .item-picture .image-overlay span,
.touch-screen #projects-carousel .item-picture .image-overlay span {
	display: block; background-image: none;
}

/* Preview Image Borders
--------------------------------------------------- */
.thumbnail, .thumbnail-colored, .thumbnail-white {
	display: block;
	position: relative;
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}
.thumbnail {
	background-color: #fff;
	border: 1px solid #e0e0e0;
	padding: 1px;
	margin: -2px;
	-webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
	   -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
	        box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}
.thumbnail-colored {
	background-color: #f8f9f4;
	border: 2px solid #c7cfc3;
	padding: 1px;
	margin: -3px;
}
.thumbnail-white {
	border: 4px solid #fff;
	margin: -4px;
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
	   -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
	        box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}
.thumbnail .image-overlay,
.thumbnail-colored .image-overlay { left: 1px; top: 1px; right: 1px; bottom: 1px; }
.thumbnail.flexslider .image-overlay,
.thumbnail-colored.flexslider .image-overlay { left: 0; top: 0; right: 0; bottom: 0; }

[class^="thumbnail"].simple { display: inline-block; }


/*-----------------------------------------------------------------------------------*/
/*	11. Single Item Details Page
/*-----------------------------------------------------------------------------------*/
.single-item-details .four.columns,
.single-item-details .one-third.columns { float: right; }

/* Media Box
--------------------------------------------------- */
.single-item-details .media-box {
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
	   -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
	        box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}
#main-content .single-item-details .media-box {
	-webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none;
}

/* Info Board
--------------------------------------------------- */
.single-item-details .info-board { margin-left: 20px; }
.single-item-details .info-board.colored {
	margin-left: 13px;
	margin-bottom: 40px;
	padding: 20px 15px 20px 27px;
	background-color: #ebeee9;
}

.data li span, .data li strong { padding-right: 5px; }
.small-bar {
	width: 60px;
	height: 0;
	border-bottom: 1px solid #dedede;
	margin-bottom: 20px;
	overflow: hidden;
}
.small-bar.colored { border-color: #c7cfc3; }

/* Text Description
--------------------------------------------------- */
.single-item-details .text-description { padding-top: 35px; }
.single-item-details .text-description.white-bg {
	padding: 35px 30px 30px;
	background-color: #fff;
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
	   -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
	        box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

/* Extra Content
--------------------------------------------------- */
.single-item-details .extra-content { margin-left: 13px; }
.single-item-details .extra-content #portfolio-wrapper,
.single-item-details .extra-content #portfolio-wrapper.small-col-space article { margin: 0; }
.single-item-details .extra-content #portfolio-wrapper.small-col-space .columns { margin-left: 38px; margin-right: 0; }
.single-item-details .extra-content #portfolio-wrapper .portfolio-item-preview:last-child { margin-bottom: 0; }


/*-----------------------------------------------------------------------------------*/
/*	12. Team
/*-----------------------------------------------------------------------------------*/

/* 2 column layout */
.team.two-cols { margin: 0 -20px; }
.team.two-cols .columns { margin-left: 20px; margin-right: 20px; }
.team.two-cols article { margin-left: 10px; margin-right: 10px; }

/* 3 column layout */
.team.three-cols { margin: 0 -15px; }
.team.three-cols .columns { margin-left: 15px; margin-right: 15px; }
.team.three-cols article { margin-left: 10px; margin-right: 10px; }

/* 4 column layout */
.team.four-cols { margin: 0 -12px; }
.team.four-cols .columns { margin-left: 13px; margin-right: 13px; }
.team.four-cols article { margin-left: 9px; margin-right: 9px; }

.team article { margin-top: 30px; }
.team .row:first-child article { margin-top: 0; }

.team img { max-width: 200px; height: 210px; }
.team h3, .team h4, .team h5, .team h6 { margin: 12px 0 0; }
.team .sub {
	font-size: 11px;
	line-height: 18px;
	color: #e8554e;
	text-transform: uppercase;
	margin-top: 2px;
}
.team article p { margin: 10px 0 0; }
.team .profile-links {
	list-style: none;
	margin: 7px 0 0;
}
.team .profile-links li {
	float: left;
	font-size: 11px;
	line-height: 18px;
	margin: 0;
}
.team .profile-links li a { color: inherit; }
.team .profile-links li a:hover { color: #333; }
.team .profile-links li span { color: #999; padding: 0 7px; }

.team .profile-links.social-icons { margin-top: 15px; }
.ie8 .team .profile-links.social-icons { margin-top: 7px; }


/*-----------------------------------------------------------------------------------*/
/*	13. Footer
/*-----------------------------------------------------------------------------------*/

#footer {
	position: relative;
	width: 100%;
	padding-top: 25px;
	background-color: #0E457A;
	z-index: 2;
}
#footer * { color: #ebeee9; }
#footer h4 {
	line-height: 22px;
	margin-bottom: 8px;
}
#footer p { margin-bottom: 0; }
#footer a:hover { color: #fff; }
#footer a:hover .title, #footer a.title:hover { color: #fff !important; }
#footer.bottom-separated .container {
	border-bottom: 1px dotted #b1b9ad;
	padding-bottom: 10px;
}
#footer.blank { padding-top: 0; height: 10px; }
#footer .underlined.colored { border-bottom-color: #b1b9ad; }

#footer .column-divider-left {
	background: url("/images/backgrounds/footer-divider.png") no-repeat left top;
	margin-left: 20px;
	padding-left: 40px;
	min-height: 100px;
}
#footer .column-divider-right {
	background: uurl("/images/backgrounds/footer-divider.png") no-repeat right top;
	margin-right: 20px;
	padding-right: 40px;
	min-height: 100px;
}

#footer .footer-nav { margin: 0; }
#footer .footer-nav li { margin-bottom: 3px; }

#footer .button {
	color: #fff;
	background-color: #b1b9ad;
	background-color: rgba(199, 207, 195, 0.4);
	background-image: none;
	border-bottom-color: #b1b9ad;
}
#footer .button:hover { background-color: rgba(199, 207, 195, 0.5); }

#footer .flickr-widget .flickr_badge_image { background-color: #ebeee9; }
#footer .flickr-widget .flickr_badge_image:hover {
	-webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none;
}
#footer .social-icons { display: table-cell; }

/* Contact details
--------------------------------------------------- */
#footer #contact-details { float: right; }
#footer .contact-data {
	float: left;
	width: 200px;
}
#footer .contact-data > div { padding-right: 20px; }
#footer .contact-description {
	float: left;
	width: 320px;
}
#footer .contact-description > div {
	border-left: 1px dotted #b1b9ad;
	padding-left: 40px;
}

/* Subscription form
--------------------------------------------------- */
#footer .form-wrapper { min-height: 55px; }
#subscription-form {
	position: relative;
	margin-bottom: 3px;
}
#footer input[type="email"] {
	width: 207px;
	background-color: #fff;
	border: 3px solid #c7cfc3;
	color: #777;
	margin: 0;
	padding: 5px 31px 5px 6px;
}
#footer input[type="email"].error{ border-color: #ffaaaa; }
#footer input[type="submit"] {
	position: absolute;
	top: 7px;
	right: 8px;
	width: 19px;
	height: 19px;
	padding: 0;
	background: #c7cfc3 url("/images/icons/button-arrow.png") no-repeat 8px 5px;
	border: none;
	-webkit-border-radius: 9px;
       -moz-border-radius: 9px;
            border-radius: 9px;
}
#footer input[type="submit"]:hover { background-color: #959c92; }

#info-share .form-wrapper {
	float: left;
	margin-right: 20px;
}
#info-share input[type="email"] {
	width: 225px;
	-webkit-border-radius: 0; -moz-border-radius: 0; border-radius: 0;
}
.ie8 #info-share #subscription-form,
#info-share .footer-message {
	width: 268px;
}

.footer-message {
	font-size: 12px;
	line-height: 19px;
	color: #fff;
}
.footer-message.info {
}
.footer-message.error {
}

/* Footer Bottom
--------------------------------------------------- */
#footer-bottom {
	position: relative;
	width: 100%;
	padding: 15px 0;
	background-color: #959c92;
	z-index: 3;
}
#footer.blank + #footer-bottom { padding: 10px 0; }

#footer-bottom .footer-nav {
	list-style: none;
	margin: 0;
}
#footer-bottom .footer-nav li {
	float: left;
	margin-bottom: 5px;
}
#footer-bottom .footer-nav a {
	font-size: 12px;
	font-weight: 600;
	line-height: 19px;
	color: #ebeee9;
	text-transform: uppercase;
	padding: 0 30px 0 0;
}
.ie8 #footer-bottom .footer-nav a { font-family: OpenSansSemibold, Arial, "Helvetica Neue", Helvetica, sans-serif; }
#footer-bottom .footer-nav li:last-child a { padding-right: 0; }
#footer-bottom .footer-nav li a:hover { color: #fff; }

#footer-bottom .links {
	list-style: none;
	margin: 0;
}
#footer-bottom .links li {
	float: left;
	margin: 0;
}
#footer-bottom .links li span {
	color: #b1b9ad;
	padding: 0 10px;
}
#footer-bottom .links * {
	font-size: 12px;
	line-height: 19px;
	color: #c7cfc3;
}
#footer-bottom .links a:hover { color: #ebeee9; }

/* Scroll Top
--------------------------------------------------- */
.scroll-top {
	font-size: 11px;
	line-height: 17px;
	cursor: pointer;
}
#footer .scroll-top,
.stripe .scroll-top {
	position: absolute;
	display: block;
	top: -49px;
	right: 10px;
	height: 24px;
	border-left: 1px solid #c7cfc3;
	padding: 0 0 0 20px;
}
.stripe .scroll-top { top: -65px; }
.stripe.bottom .scroll-top { top: -50px; }
.stripe.map-wrapper .scroll-top { top: -26px; }

#footer .scroll-top .arrow-top,
.stripe .scroll-top .arrow-top {
	position: absolute;
	display: block;
	top: 0;
	left: -18px;
	width: 17px;
	height: 24px;
	background: url("/images/colored_theme/scroll-top-arrow.png") no-repeat 0 4px;
}

#footer .scroll-top span,
.stripe .scroll-top span {
	font-size: 12px;
	line-height: 19px;
	color: #959c92;
	opacity: 0.7;
	-webkit-transition: opacity 0.15s ease;
	   -moz-transition: opacity 0.15s ease;
	     -o-transition: opacity 0.15s ease;
	        transition: opacity 0.15s ease;
}
#footer .scroll-top:hover span,
.stripe .scroll-top:hover span { opacity: 1; }


/*-----------------------------------------------------------------------------------*/
/*	14. Sidebar
/*-----------------------------------------------------------------------------------*/

#sidebar .widget { margin-top: 40px; }
#sidebar .widget:first-child { margin-top: 0; }
#sidebar.page-left-col .widget { width: 160px; }
#sidebar.page-right-col .widget { width: 220px; }
#sidebar.page-left-col .widget:first-child + .widget { margin-top: 80px; }

#sidebar .widget h4, #sidebar .widget h5 {
	position: relative;
	margin-bottom: 10px;
}
#sidebar .widget.separator h4,
#sidebar .widget.separator h5 {
	margin-bottom: 25px;
}
#sidebar .widget.separator h4:after,
#sidebar .widget.separator h5:after {
	position: absolute;
	display: block;
	left: -10px; right: 0; bottom: -10px;
	height: 2px;
	content: '';
	background: -webkit-linear-gradient(left, #f0f2ed, #d5d5d5 5%, #d5d5d5 95%, #f0f2ed);
	background: -moz-linear-gradient(left, #f0f2ed, #d5d5d5 5%, #d5d5d5 95%, #f0f2ed);
	background: -o-linear-gradient(left, #f0f2ed, #d5d5d5 5%, #d5d5d5 95%, #f0f2ed);
	background: -ms-linear-gradient(left, #f0f2ed, #d5d5d5 5%, #d5d5d5 95%, #f0f2ed);
	background: linear-gradient(to right, #f0f2ed, #d5d5d5 5%, #d5d5d5 95%, #f0f2ed);
}
.ie #sidebar .widget.separator h4:after,
.ie #sidebar .widget.separator h5:after {
	background-color: #d5d5d5;
}
#sidebar blockquote:not(.testimonial),
#sidebar blockquote:not(.testimonial) p { font-size: 14px; line-height: 23px; }
.ie8 #sidebar blockquote,
.ie8 #sidebar blockquote p { font-size: 12px; line-height: 19px; }
#sidebar blockquote cite { font-size: 12px; }

#sidebar ul.disc, #sidebar ul.square {
	padding-left: 15px; overflow: visible;
}

/* Side menu
--------------------------------------------------- */
#sidebar.page-left-col .side-menu {
	margin: 0 -29px 0 0;
	background: url("/images/backgrounds/separator-left-side-menu.png") no-repeat left bottom;
}
#sidebar.page-right-col .side-menu {
	margin: 0 0 0 -29px;
	background: url("/images/backgrounds/separator-right-side-menu.png") no-repeat right bottom;
}
.side-menu li {
	position: relative;
	margin: 0;
}
#sidebar.page-left-col .side-menu li:hover {
	background: url("/images/colored_theme/hover-bg-left-side-menu.png") repeat-y left top;
}
#sidebar.page-left-col .side-menu li.current,
#sidebar.page-left-col .side-menu li.current:hover {
	background: url("/images/colored_theme/current-bg-left-side-menu.png") repeat-y left top;
	margin-right: -1px;
}
#sidebar.page-right-col .side-menu li:hover {
	background: url("/images/colored_theme/hover-bg-right-side-menu.png") repeat-y right top;
}
#sidebar.page-right-col .side-menu li.current,
#sidebar.page-right-col .side-menu li.current:hover {
	background: uurl("/images/colored_theme/current-bg-right-side-menu.png") repeat-y right top;
	margin-left: -1px;
}
.side-menu li a {
	position: relative;
	display: block;
	color: #777;
}
#sidebar.page-left-col .side-menu li a {
	padding: 9px 29px 10px 5px;
	background: url("/images/backgrounds/separator-left-side-menu.png") no-repeat left top;
	text-align: right;
}
#sidebar.page-right-col .side-menu li a {
	padding: 9px 5px 10px 29px;
	background: url("/images/backgrounds/separator-right-side-menu.png") no-repeat right top;
}
#sidebar.page-left-col .side-menu li a:hover, #sidebar.page-left-col .side-menu li.current a {
	background-image: url("/images/colored_theme/separator-left-side-menu-colored.png");
}
#sidebar.page-right-col .side-menu li a:hover, #sidebar.page-right-col .side-menu li.current a {
	background-image: url("/images/colored_theme/separator-right-side-menu-colored.png");
}
#sidebar.page-left-col .side-menu li.current a { padding-right: 30px; }
#sidebar.page-right-col .side-menu li.current a { padding-left: 30px; }
.side-menu li a:hover { color: #555; }
.side-menu li a:after {
	position: absolute;
	display: block;
	left: 0; bottom: -1px; width: 100%; height: 1px;
	content: '';
	visibility: hidden;
	z-index: 2;
}
#sidebar.page-left-col .side-menu li a:after {
	background: url("/images/colored_theme/separator-left-side-menu-colored.png") no-repeat left top;
}
#sidebar.page-right-col .side-menu li a:after {
	background: url("/images/colored_theme/separator-right-side-menu-colored.png") no-repeat right top;
}
.side-menu li:last-child a:after { bottom: 0; }
.side-menu li:hover a:after, .side-menu li.current a:after { visibility: visible; }

.side-menu li.current { font-weight: 600; }
.side-menu li.current a { color: #777; }
.side-menu li span {
	color: #b1b9ad;
	margin-left: 5px;
	white-space: nowrap;
}
.side-menu li br + span { margin-left: 0; }

/* Side menu with middle font size */
.side-menu.middle-font-size li {
	font: italic 15px/22px "Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif;
}
.side-menu.middle-font-size a { color: #959c92; }
.side-menu.middle-font-size a:hover { color: #777; }

/* For Internet Explorer 8 */
.ie8 .side-menu.middle-font-size li { font-family: OpenSansItalic, Arial, "Helvetica Neue", Helvetica, sans-serif; }
.ie8 .side-menu li.current { font-family: OpenSansSemiboldItalic; }
.ie8 #sidebar.page-left-col .side-menu li:hover + li a,
.ie8 #sidebar.page-left-col .side-menu li.current + li a,
.ie8 #sidebar.page-right-col .side-menu li:hover + li a,
.ie8 #sidebar.page-right-col .side-menu li.current + li a { background: none; }
.ie8 #sidebar.page-right-col .side-menu li:hover + li.current a, .ie8 #sidebar.page-right-col .side-menu li.current + li:hover a {
	background: url("/images/colored_theme/separator-right-side-menu-colored.png") no-repeat right top;
}
.ie8 #sidebar.page-left-col .side-menu li:hover + li.current a, .ie8 #sidebar.page-left-col .side-menu li.current + li:hover a {
	background: url("/images/colored_theme/separator-left-side-menu-colored.png") no-repeat left top;
}
.ie8 .side-menu { padding-bottom: 1px; }


/*-----------------------------------------------------------------------------------*/
/*	15. Blog
/*-----------------------------------------------------------------------------------*/

.blog article {
	margin-bottom: 35px;
	padding-bottom: 30px;
	border-bottom: 1px dotted #c7cfc3;
}
.blog .post-media { margin-bottom: 20px; }
.blog .post-date {
	float: left;
	width: 45px;
}
.blog .post-date span { display: block; text-align: center; }
.blog .post-date .day {
	font-size: 14px;
	font-weight: bold;
	padding-top: 3px;
	background-color: #f0f2ed;
}
.ie8 .blog .post-date .day { font-family: OpenSansBold, Arial, "Helvetica Neue", Helvetica, sans-serif; }
.blog .post-date .month {
	font-size: 11px;
	line-height: 18px;
	margin-top: -2px;
	padding-bottom: 6px;
	background-color: #f0f2ed;
}
.blog .post-date .year {
	color: #fff;
	background-color: #d5d5d5;
}
.blog .post-content { margin-left: 65px; }
.blog .post-content.full-width { margin-left: 0 !important; }
.blog h3 { margin-bottom: 10px; }
.blog .post-meta { margin-bottom: 15px; }
.blog .post-meta > span {
	display: inline-block;
	margin-right: 15px;
}
.blog .post-meta > span:last-child { margin-right: 0; }
.blog .post-meta .fa { /* vector icons - Font Awesome */
	font-size: 14px;
	line-height: 14px;
	color: #b1b9ad;
	margin-right: 6px;
}
.blog .post-meta.slash-separated > span { /* with slash separator */
	background: url("/images/icons/slash.png") no-repeat 0 3px;
	padding: 0 9px 0 15px;
	margin: 0;
}
.blog .post-meta.slash-separated > span:first-child {
	background: none;
	padding-left: 0;
}
.blog .post-meta.slash-separated > span:last-child { padding-right: 0; }
.blog .post-meta.small-font-size > span { margin: 0; }
.blog .post-meta.small-font-size > span > span { padding: 0 10px; }

/* Medium size image
--------------------------------------------------- */
.blog.medium-size article { margin-bottom: 30px; }
.blog.medium-size .post-media {
	float: left;
	width: 270px;
	margin-bottom: 0;
}
.blog.medium-size .post-content { margin-left: 290px; }

/* Small size image
--------------------------------------------------- */
.blog.small-size article { margin-bottom: 30px; }
.blog.small-size .post-media {
	float: left;
	width: 150px;
	margin-bottom: 0;
	-webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none;
}
.blog.small-size .post-content { margin-left: 170px; }
.blog.small-size .post-meta { margin-bottom: 20px; }

/* Grid Style - Blog/News
--------------------------------------------------- */
.blog-grid-style { margin: 0 -15px; }
.blog-grid-style .columns { margin-left: 15px; margin-right: 15px; }
.blog-grid-style .post-preview { margin-bottom: 40px; }

.blog-grid-style article {
	margin: 0 10px;
	padding-bottom: 10px;
	background-color: #fff;
	-webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
	   -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
	        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}
.blog-grid-style .post-content {
	position: relative;
	height: 154px;
	padding: 25px 20px 0;
	overflow: hidden;
}
.blog-grid-style .post-date {
	font-size: 12px;
	line-height: 19px;
	color: #999;
	margin-bottom: 5px;
}
.blog-grid-style .post-content h3 {
	font-size: 19px;
	line-height: 26px;
	color: #333;
	margin-bottom: 10px;
}
.blog-grid-style .post-content h4 {
	font-size: 17px;
	line-height: 25px;
}
.blog-grid-style .post-content p { margin-bottom: 0; }
.blog-grid-style .post-media + .post-content { padding-top: 12px; }
.blog-grid-style .post-media + .post-content:before {
	position: absolute;
	display: block;
	left: 0; top: 0; width: 100%; height: 3px;
	content: '';
	background-color: #c7cfc3;
	background-image: -webkit-linear-gradient(left, #e8554e, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), #e8554e);
	background-image: -moz-linear-gradient(left, #e8554e, rgba(255, 255, 255, 0), #e8554e);
	background-image: -o-linear-gradient(left, #e8554e, rgba(255, 255, 255, 0), #e8554e);
	background-image: -ms-linear-gradient(left, #e8554e, rgba(255, 255, 255, 0), #e8554e);
}
.blog-grid-style .post-content:after {
	position: absolute;
	display: block;
	left: 0; bottom: 0; width: 100%; height: 10px;
	content: '';
	background: -webkit-linear-gradient(bottom, #ffffff, rgba(255, 255, 255, 0));
	background: -moz-linear-gradient(bottom, #ffffff, rgba(255, 255, 255, 0));
	background: -o-linear-gradient(bottom, #ffffff, rgba(255, 255, 255, 0));
	background: -ms-linear-gradient(bottom, #ffffff, rgba(255, 255, 255, 0));
	background: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
}
.blog-grid-style .post-meta {
	margin: 7px 0 -5px;
	padding: 10px 20px 7px;
	border-top: 1px dotted #dedede;
}
.blog-grid-style .post-meta > span { display: inline-block; }
.blog-grid-style .post-meta > span > span { padding: 0 5px 0 7px; }

/* Single post
--------------------------------------------------- */
.blog .single-post {
	border-bottom: none;
	margin-bottom: 0;
	padding-bottom: 0;
}

.blog .single-post .info-box img { float: left; margin: 3px 15px 10px 0; border: 1px solid #fafafa; }
.blog .single-post .about-author-info { margin-left: 85px; }

/* Comments
--------------------------------------------------- */
.comments-list {
	list-style: none;
	border-bottom: 1px dotted #c7cfc3;
	margin: 25px 0 35px;
	padding-bottom: 30px;
}

.comment {
	border-top: 1px dotted #c7cfc3;
	margin: 30px 0 0;
	padding-top: 25px;
}
.comment:first-child { border: none; margin-top: 0; padding-top: 0; }

.comment article {
	border: none;
	margin: 0;
	padding: 0;
}
.comment img {
	float: left;
	margin: 0 15px 10px 0;
	border: 1px solid #fff;
}

.comment .comment-meta { margin-bottom: 10px; }
.comment .comment-meta h5, .comment .comment-meta h6 { display: inline; }
.comment .comment-meta span { padding: 0 6px 0 3px; }
.comment .date {
	font-style: italic;
	margin-bottom: 0;
}
.comment .date a { color: inherit; }
.comment .date a:hover, .comment .date a > *:hover { color: #e8554e; }
.ie8 .comment .date { font-family: OpenSansItalic, Arial, "Helvetica Neue", Helvetica, sans-serif; }

.comment .comment-content { margin-left: 85px; }
.comment .comment-content p { margin-bottom: 0; }

.comment .children {
	margin: 0;
	padding: 0 0 0 85px;
}
.comment .children .comment {
	border-top: 1px dotted #c7cfc3;
	margin: 30px 0 0;
	padding-top: 25px;
}

/*-----------------------------------------------------------------------------------*/
/*	16. Announcements
/*-----------------------------------------------------------------------------------*/

.announcements article {
	border-top: 1px dotted #c7cfc3;
	margin-top: 20px;
	padding-top: 15px;
}
.announcements > article:first-child,
.announcements section article:first-child,
.announcements .row:first-child article { border: none; margin-top: 0; padding-top: 0; }
.announcements img {
	float: left;
	margin: 5px 15px 0 0;
	width: 135px;
}
.announcements p { margin-bottom: 10px; }

.announcements .preview-text h5,
.announcements .preview-text h6,
.announcements .preview-text p { margin-bottom: 5px; }
.announcements .preview-text span { display: block; font-style: italic; }
.ie8 .announcements .preview-text span { font-family: OpenSansItalic, Arial, "Helvetica Neue", Helvetica, sans-serif; }
.announcements .preview-text { display: table; }

.announcements [class^="thumbnail"],
.announcements [class*=" thumbnail"] {
	float: left;
	margin: 3px 15px 0 0;
}
.announcements [class^="thumbnail"] img,
.announcements [class*=" thumbnail"] img {
	float: none !important;
	margin: 0 !important;
}

aside .announcements [class^="thumbnail"],
aside .announcements [class*=" thumbnail"] { margin: 3px 12px 0 0; }
aside .announcements .thumbnail {
	-webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0 0 2px rgba(0, 0, 0, 0.05);
	        box-shadow: 0 0 2px rgba(0, 0, 0, 0.05);
}
aside .announcements article { margin-top: 15px; padding-top: 12px; }
aside .announcements article:first-child { margin-top: 0; padding-top: 0; }
aside .announcements img { margin: 3px 12px 0 0; }
aside .announcements img:hover {
	filter: alpha(opacity=80);
	opacity: 0.8;
}

.announcements.tight article {
	margin-top: 12px;
	padding-top: 7px;
}
.announcements.tight > article:first-child,
.announcements.tight section article:first-child,
.announcements.tight .row:first-child article { border: none; margin-top: 0; padding-top: 0; }


/*-----------------------------------------------------------------------------------*/
/*	17. Info Box
/*-----------------------------------------------------------------------------------*/

.info-box {
	padding: 15px 20px;
	background-color: #ebeee9;
	border: 1px solid #e6e9e4;
}
.info-box.light {
	background-color: #f0f2ed;
	border: 1px solid #e6e9e4;
}
.info-box.light.gradient {
	background: -webkit-linear-gradient(bottom, #f8f9f4, #e6e9e4);
	background: -moz-linear-gradient(bottom, #f8f9f4, #e6e9e4);
	background: -o-linear-gradient(bottom, #f8f9f4, #e6e9e4);
	background: -ms-linear-gradient(bottom, #f8f9f4, #e6e9e4);
	background: linear-gradient(to top, #f8f9f4, #e6e9e4);
}
.info-box.dark {
	background-color: #959c92;
	border: none;
}
.info-box.colored {
	background-color: #e8554e;
	border: none;
}
.info-box.dark *, .info-box.colored * { color: #fff; }

.info-box.white-bg { background-color: #fff; }

.info-box.no-border { border: none; }
.info-box.rounded {
	-webkit-border-radius: 8px;
	   -moz-border-radius: 8px;
	        border-radius: 8px;
}
.info-box.shadow {
	-webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
	   -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
	        box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
}
.info-box.tight { padding: 15px; }

/* Action box
--------------------------------------------------- */
.info-box.action-box { border-left: 3px solid #c7cfc3; }
.info-box .action-box-container {
	display: table;
	width: 100%;
}
.info-box .action-box-container > div {
	display: table-cell;
	vertical-align: middle;
}
.info-box .action-button {
	padding: 0 5px 0 40px;
	text-align: right;
}

.info-box.transparent {
	padding: 0;
	background: none;
	border: none;
	-webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none;
	-webkit-border-radius: 0; -moz-border-radius: 0; border-radius: 0;
}


/*-----------------------------------------------------------------------------------*/
/*	18. Testimonials
/*-----------------------------------------------------------------------------------*/

blockquote.testimonial {
	margin: 0 0 20px;
	padding: 0;
	border-left: none;
}
blockquote.testimonial .quote-icon {
	float: left;
	width: 27px;
	height: 20px;
	margin: 5px 15px 5px 1px;
	background: url("/images/icons/quote.png") no-repeat 0 0;
}
blockquote.testimonial span {
	display: block;
	font-style: normal;
	text-align: right;
}
blockquote.testimonial span.left { text-align: left; }
blockquote.testimonial.align-center span { text-align: center; }

/* Testimonials Carousel
--------------------------------------------------- */
.testimonials-carousel li {
	display: none;
	float: left;
	margin: 0;
	padding-right: 20px;
}
.testimonials-carousel li:first-child,
.testimonials-carousel.initiated li,
.no-js .testimonials-carousel li { display: block; }

.testimonials-carousel li blockquote.testimonial { margin-bottom: 0; }
.testimonials-holder .carousel-nav { top: -36px; }
.divider-pattern + .testimonials-holder .carousel-nav { top: -49px; }
.underlined + .testimonials-holder .carousel-nav { top: -47px; }
#sidebar .widget.separator h4 + .testimonials-holder .carousel-nav { top: -46px; }


/*-----------------------------------------------------------------------------------*/
/*	19. Accordion / Toggle
/*-----------------------------------------------------------------------------------*/

.toggle-trigger { cursor: pointer; }
.toggle-container + .toggle-trigger { margin-top: 8px; }
.toggle-trigger * { margin-bottom: 0 !important; }

.toggle-trigger {
	background: #f0f2ed url("/images/icons/toggle-buttons.png") no-repeat 15px 16px;
	border: 1px solid #e6e9e4;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	padding: 8px 20px 0 40px;
}
.toggle-trigger.active {
	background-color: #fff;
	background-position: 15px -81px;
	border-bottom: none;
	-webkit-border-radius: 5px 5px 0 0;
	   -moz-border-radius: 5px 5px 0 0;
	        border-radius: 5px 5px 0 0;
}
.toggle-trigger a {
	display: block;
	margin-right: 10px;
	padding-bottom: 9px;
	-webkit-transition: border-color 0s;
	   -moz-transition: border-color 0s;
		 -o-transition: border-color 0s;
			transition: border-color 0s;
	outline: none;
}
.toggle-trigger a strong { line-height: 24px; }
.toggle-trigger a, .toggle-trigger a *,
.toggle-trigger a:hover, .toggle-trigger a:hover * { color: #666; }
.toggle-trigger.active a { border-bottom: 1px dotted #ccc; }

.toggle-container .content { padding: 15px 20px 20px 40px; }
.toggle-container {
	display: none;
	background-color: #fff;
	border: 1px solid #e6e9e4;
	border-top: none;
	-webkit-border-radius: 0 0 5px 5px;
	   -moz-border-radius: 0 0 5px 5px;
	        border-radius: 0 0 5px 5px;
}
.no-js .toggle-container { display: block; }

/* specific styles for screens with 768 pixels wide and higher (overwrite the basic styles) */
@media only screen and (min-width: 768px) {
	.white-bg .toggle-trigger {
		background-color: #fafafa;
		border-color: #dedede;
	}
	.white-bg .toggle-trigger.active { background-color: #fefefe; }
	.white-bg .toggle-container {
		background-color: #fefefe;
		border-color: #dedede;
	}
}


/*-----------------------------------------------------------------------------------*/
/*	20. Tabs
/*-----------------------------------------------------------------------------------*/

.tabs-nav {
	list-style: none;
	margin: 0;
	padding: 0;
}
.tabs-nav li {
	position: relative;
	float: left;
	margin: 0 0 2px;
	padding-right: 2px;
}
.tabs-nav li:last-child { padding-right: 0; }
.tabs-nav li * { margin-bottom: 0; }
.tabs-nav li a, .tabs-nav li * { color: #777; }
.tabs-nav li a {
	display: block;
	font-size: 13px;
	line-height: 18px;
	background-color: #ebeee9;
	padding: 6px 12px;
	outline: none;
}
.tabs-nav.uppercase li a {
	font-size: 12px;
	line-height: 17px;
	padding-top: 7px;
}
.tabs-nav li a:hover, .tabs-nav li a:hover * { color: #777; }
.tabs-nav li a:hover { background-color: #e6e9e4; }
.tabs-nav li.active *, .tabs-nav li.active a:hover, .tabs-nav li.active a:hover * { color: #fff; }
.tabs-nav li.active a, .tabs-nav li.active a:hover { background-color: #e8554e; }
.tabs-nav li.active:before {
	position: absolute;
	display: block;
	left: 50%; bottom: 0; width: 12px; height: 7px;
	content: '';
    background: url("/images/colored_theme/tab-arrow.png") no-repeat 0 0;
	margin-bottom: -7px;
	margin-left: -6px;
}
.tabs-nav li h6 { font-weight: 400; }
.ie8 .tabs-nav li h6 { font-family: "Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif; }
.tabs-container { overflow: hidden; }
.tab-content {
	display: none;
	padding: 25px 0 20px;
}
.no-js .tab-content { display: block; }

/* bordered bottom */
.tabs-container.bordered,
.tabs-container.bordered-all { border-bottom: 2px solid #c7cfc3; margin-bottom: 30px; }
.white-bg .tabs-container.bordered,
.white-bg .tabs-container.bordered-all { border-bottom: 2px solid #e6e9e4; margin-bottom: 30px; }
.tabs-container.bordered .tab-content,
.tabs-container.bordered-all .tab-content { padding-bottom: 30px; }

/* specific styles for screens with 768 pixels wide and higher (overwrite the basic styles) */
@media only screen and (min-width: 768px) {
	.white-bg .tabs-nav li a { background-color: #f3f3f3; }
	.white-bg .tabs-nav li a:hover { background-color: #eaeaea; }
	.white-bg .tabs-nav li.active a { background-color: #e8554e; }
	
	/* bordered top */
	.tabs-nav.bordered, .tabs-nav.bordered li a { border-bottom: 1px solid #c7cfc3; }
	.tabs-nav.bordered li { margin-bottom: -1px; background-color: #f8f9f4; }
	.tabs-nav.bordered li.active a { border-bottom: 1px solid #e8554e; }
	.white-bg .tabs-nav.bordered,
	.white-bg .tabs-nav.bordered li a { border-bottom: 1px solid #e6e9e4; }
	.white-bg .tabs-nav.bordered li.active a { border-bottom: 1px solid #e8554e; }
	.white-bg .tabs-nav.bordered li { background-color: #fff; }
	
	/* bordered all sides */
	.tabs-container.bordered-all .tab-content { padding-left: 30px; padding-right: 30px; }
	.tabs-container.bordered-all {
		border-left: 1px solid #c7cfc3;
		border-right: 1px solid #c7cfc3;
		border-bottom: 1px solid #c7cfc3;
	}
	.white-bg .tabs-container.bordered-all { border-color: #e6e9e4; border-width: 1px; }
}


/*-----------------------------------------------------------------------------------*/
/*	21. Message Boxes
/*-----------------------------------------------------------------------------------*/

.message-box {
	position: relative;
	margin-bottom: 15px;
	padding: 15px 20px;
	border: 1px solid #c7cfc3;
	z-index: 900;
}
.message-box.short { padding: 7px 15px; }
.message-box.closable { padding-right: 55px; }
.message-box p { margin: 0; }
.message-box h5 { margin-bottom: 7px; }

.message-box.success {
	background-color: #edf9e1;
	color: #609026;
	border-color: #c4ddad;
}
.message-box.error {
	background-color: #ffeaea;
	color: #df5a5a;
	border-color: #fcc8c8;
}
.message-box.info {
	background-color: #ecf8fe;
	color: #5997b6;
	border-color: #cbe4f1;
}
.message-box.warning {
	background-color: #fcfbe2;
	color: #9f892f;
	border-color: #e9e19f;
}
.message-box.success h5 { color: #416514; }
.message-box.error h5 { color: #c74545; }
.message-box.info h5 { color: #427a96; }
.message-box.warning h5 { color: #7f6c1e; }

.message-box .close {
	position: absolute;
	display: block;
	height: 16px;
	width: 16px;
	right: 16px;
	top: 50%;
	margin-top: -8px;
	background: url("/images/icons/message-box-close-icons.png") no-repeat;
}
.message-box.success .close { background-position: 4px 4px; }
.message-box.error .close { background-position: 4px -12px; }
.message-box.info .close { background-position: 4px -28px; }
.message-box.warning .close { background-position: 4px -44px; }


/*-----------------------------------------------------------------------------------*/
/*	22. Flickr Widget (Photostream)
/*-----------------------------------------------------------------------------------*/

.flickr-widget { margin: 0 -20px -20px 0; }
.flickr-widget img { width: 100%; max-width: 100%; height: auto; }
.flickr-widget .flickr_badge_image {
	float: left;
	width: 75px;
	height: 75px;
	margin: 0 20px 20px 0;
	padding: 3px;
	background-color: #fff;
	-webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
	   -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
	        box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
}
.flickr-widget a:hover img {
	filter: alpha(opacity=80);
	opacity: 0.8;
}

aside .flickr-widget .flickr_badge_image {
	-webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
	   -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
	        box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}


/*-----------------------------------------------------------------------------------*/
/*	23. Preview Images List
/*-----------------------------------------------------------------------------------*/

.preview-images-list { margin: 0 -20px -20px 0; }
.preview-images-list img { max-width: 100%; height: auto; }
.preview-images-list a {
	float: left;
	display: block;
	margin: 0 20px 20px 0;
	padding: 3px;
	background-color: #fff;
	-webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
	   -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
	        box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
}
.preview-images-list a:hover img {
	filter: alpha(opacity=80);
	opacity: 0.8;
}

aside .preview-images-list a {
	width: 75px;
	height: auto;
	-webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
	   -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
	        box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

.preview-images-list.thin-border a {
	padding: 1px;
	border: 1px solid #e0e0e0;
	-webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
	   -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
	        box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}


/*-----------------------------------------------------------------------------------*/
/*	24. Twitter Feed
/*-----------------------------------------------------------------------------------*/

.tweets .tweet_list { list-style: none; margin: 0; padding: 0; }
.tweets .tweet_list li {
	margin-bottom: 15px;
	word-wrap: break-word;
}
.tweets .tweet_avatar { padding-right: 7px; float: left; }
.tweets .tweet_even:last-child, .tweets .tweet_odd:last-child { margin-bottom: 0; }
.tweets .tweet_text { display: block; }
.tweets .tweet_time { display: block; margin-top: 5px; font-style: italic;}
.tweets .tweet_time a { color: inherit; }
.tweets .tweet_time a:hover { color: #e8554e; }

aside .tweets .tweet_text a { color: #333; }
aside .tweets .tweet_text a:hover,
aside .tweets .tweet_time a:hover { color: #e8554e; }

.tweets .tweet_text, .tweets .tweet_time { margin-left: 23px; }
.tweets .tweet_list li { position: relative; }
.tweets .tweet_list li:before {
	position: absolute;
	top: 4px;
	left: 0;
	content: "\f099";
	font-family: FontAwesome;
	font-size: 18px;
	line-height: 18px;
	color: #c7cfc3;
	-webkit-font-smoothing: antialiased;
}

.ie8 .tweets .tweet_time { font-family: OpenSansItalic, Arial, "Helvetica Neue", Helvetica, sans-serif; }


/*-----------------------------------------------------------------------------------*/
/*	25. Social Icons
/*-----------------------------------------------------------------------------------*/

.social-icons {
	list-style: none;
	margin: 0;
}
.social-icons li {
	display: block;
	float: left;
	margin: 0 5px 5px 0;
	text-indent: -9999px;
	background-color: #424242;
	background-image: url("/images/icons/social-icons.png");
	background-repeat: no-repeat;
	cursor: pointer;
	opacity: 0.85;
	-webkit-transition: all 0.2s ease-out;
	   -moz-transition: all 0.2s ease-out;
		 -o-transition: all 0.2s ease-out;
	        transition: all 0.2s ease-out;
}
.ie8 .social-icons li { filter: alpha(opacity=85); }
.social-icons li a {
	display: block;
	height: 32px;
	width: 32px;
}
.social-icons li:hover { background-color: #ebeee9; }
.social-icons.round li {
	margin: 0 7px 7px 0;
	-webkit-border-radius: 16px;
	   -moz-border-radius: 16px;
	        border-radius: 16px;
}
.social-icons li:last-child,
.social-icons.round li:last-child { margin-right: 0; }

.social-icons .apple { background-position: 0 0; }
.social-icons .behance { background-position: -32px 0; }
.social-icons .delicious { background-position: -64px 0; }
.social-icons .deviantart { background-position: -96px 0; }
.social-icons .digg { background-position: -128px 0; }
.social-icons .dribbble { background-position: -160px 0; }
.social-icons .email { background-position: -192px 0; }
.social-icons .facebook { background-position: -224px 0; }
.social-icons .flickr { background-position: -256px 0; }
.social-icons .forrst { background-position: -288px 0; }
.social-icons .google { background-position: -320px 0; }
.social-icons .google-plus { background-position: -352px 0; }
.social-icons .lastfm { background-position: -384px 0; }
.social-icons .linkedin { background-position: -416px 0; }
.social-icons .myspace { background-position: -448px 0; }
.social-icons .picasa { background-position: -480px 0; }
.social-icons .pinterest { background-position: -512px 0; }
.social-icons .rss { background-position: -544px 0; }
.social-icons .skype { background-position: -576px 0; }
.social-icons .stumbleupon { background-position: -608px 0; }
.social-icons .tumblr { background-position: -640px 0; }
.social-icons .twitter { background-position: -672px 0; }
.social-icons .vimeo { background-position: -704px 0; }
.social-icons .yahoo { background-position: -736px 0; }
.social-icons .youtube { background-position: -768px 0; }

#footer .social-icons .apple:hover { background-position: 0 -32px; }
#footer .social-icons .behance:hover { background-position: -32px -32px; }
#footer .social-icons .delicious:hover { background-position: -64px -32px; }
#footer .social-icons .deviantart:hover { background-position: -96px -32px; }
#footer .social-icons .digg:hover { background-position: -128px -32px; }
#footer .social-icons .dribbble:hover { background-position: -160px -32px; }
#footer .social-icons .email:hover { background-position: -192px -32px; }
#footer .social-icons .facebook:hover { background-position: -224px -32px; }
#footer .social-icons .flickr:hover { background-position: -256px -32px; }
#footer .social-icons .forrst:hover { background-position: -288px -32px; }
#footer .social-icons .google:hover { background-position: -320px -32px; }
#footer .social-icons .google-plus:hover { background-position: -352px -32px; }
#footer .social-icons .lastfm:hover { background-position: -384px -32px; }
#footer .social-icons .linkedin:hover { background-position: -416px -32px; }
#footer .social-icons .myspace:hover { background-position: -448px -32px; }
#footer .social-icons .picasa:hover { background-position: -480px -32px; }
#footer .social-icons .pinterest:hover { background-position: -512px -32px; }
#footer .social-icons .rss:hover { background-position: -544px -32px; }
#footer .social-icons .skype:hover { background-position: -576px -32px; }
#footer .social-icons .stumbleupon:hover { background-position: -608px -32px; }
#footer .social-icons .tumblr:hover { background-position: -640px -32px; }
#footer .social-icons .twitter:hover { background-position: -672px -32px; }
#footer .social-icons .vimeo:hover { background-position: -704px -32px; }
#footer .social-icons .yahoo:hover { background-position: -736px -32px; }
#footer .social-icons .youtube:hover { background-position: -768px -32px; }

#main .social-icons li:hover { background-color: #959c92; opacity: 1; }
.ie8 #main .social-icons li {
	background-color: #b1b9ad;
	filter: alpha(opacity=85);
}
.ie8 #main .social-icons li:hover {
	background-color: #959c92;
	filter: alpha(opacity=100);
}

/* Small-size icons
--------------------------------------------------- */
.social-icons.small-size li {
	margin: 0 6px 6px 0;
	background-color: #b1b9ad;
	background-size: 600px 48px;
}
.social-icons.small-size.round li {
	margin: 0 6px 6px 0;
	-webkit-border-radius: 12px;
	   -moz-border-radius: 12px;
	        border-radius: 12px;
}
.social-icons.small-size li:last-child,
.social-icons.small-size.round li:last-child { margin-right: 0; }
.social-icons.small-size li:hover { background-color: #959c92; opacity: 1; }
.social-icons.small-size li a {
	height: 24px;
	width: 24px;
}

.social-icons.small-size .apple { background-position: 0 0; }
.social-icons.small-size .behance { background-position: -24px 0; }
.social-icons.small-size .delicious { background-position: -48px 0; }
.social-icons.small-size .deviantart { background-position: -72px 0; }
.social-icons.small-size .digg { background-position: -96px 0; }
.social-icons.small-size .dribbble { background-position: -120px 0; }
.social-icons.small-size .email { background-position: -144px 0; }
.social-icons.small-size .facebook { background-position: -168px 0; }
.social-icons.small-size .flickr { background-position: -192px 0; }
.social-icons.small-size .forrst { background-position: -216px 0; }
.social-icons.small-size .google { background-position: -240px 0; }
.social-icons.small-size .google-plus { background-position: -264px 0; }
.social-icons.small-size .lastfm { background-position: -288px 0; }
.social-icons.small-size .linkedin { background-position: -312px 0; }
.social-icons.small-size .myspace { background-position: -336px 0; }
.social-icons.small-size .picasa { background-position: -360px 0; }
.social-icons.small-size .pinterest { background-position: -384px 0; }
.social-icons.small-size .rss { background-position: -408px 0; }
.social-icons.small-size .skype { background-position: -432px 0; }
.social-icons.small-size .stumbleupon { background-position: -456px 0; }
.social-icons.small-size .tumblr { background-position: -480px 0; }
.social-icons.small-size .twitter { background-position: -504px 0; }
.social-icons.small-size .vimeo { background-position: -528px 0; }
.social-icons.small-size .yahoo { background-position: -552px 0; }
.social-icons.small-size .youtube { background-position: -576px 0; }

/* Small-size icons for Internet Explorer 8
--------------------------------------------------- */
.ie8 .social-icons.small-size li a {
	height: 32px;
	width: 32px;
}
.ie8 .social-icons.small-size li,
.ie8 #main .social-icons.small-size li {
	margin: 0;
	background-color: transparent;
	filter: none;
}
.ie8 .social-icons.small-size li:hover,
.ie8 #main .social-icons.small-size li:hover { background-color: transparent; }

.ie8 .social-icons.small-size .apple { background-position: 0 -32px; }
.ie8 .social-icons.small-size .behance { background-position: -32px -32px; }
.ie8 .social-icons.small-size .delicious { background-position: -64px -32px; }
.ie8 .social-icons.small-size .deviantart { background-position: -96px -32px; }
.ie8 .social-icons.small-size .digg { background-position: -128px -32px; }
.ie8 .social-icons.small-size .dribbble { background-position: -160px -32px; }
.ie8 .social-icons.small-size .email { background-position: -192px -32px; }
.ie8 .social-icons.small-size .facebook { background-position: -224px -32px; }
.ie8 .social-icons.small-size .flickr { background-position: -256px -32px; }
.ie8 .social-icons.small-size .forrst { background-position: -288px -32px; }
.ie8 .social-icons.small-size .google { background-position: -320px -32px; }
.ie8 .social-icons.small-size .google-plus { background-position: -352px -32px; }
.ie8 .social-icons.small-size .lastfm { background-position: -384px -32px; }
.ie8 .social-icons.small-size .linkedin { background-position: -416px -32px; }
.ie8 .social-icons.small-size .myspace { background-position: -448px -32px; }
.ie8 .social-icons.small-size .picasa { background-position: -480px -32px; }
.ie8 .social-icons.small-size .pinterest { background-position: -512px -32px; }
.ie8 .social-icons.small-size .rss { background-position: -544px -32px; }
.ie8 .social-icons.small-size .skype { background-position: -576px -32px; }
.ie8 .social-icons.small-size .stumbleupon { background-position: -608px -32px; }
.ie8 .social-icons.small-size .tumblr { background-position: -640px -32px; }
.ie8 .social-icons.small-size .twitter { background-position: -672px -32px; }
.ie8 .social-icons.small-size .vimeo { background-position: -704px -32px; }
.ie8 .social-icons.small-size .yahoo { background-position: -736px -32px; }
.ie8 .social-icons.small-size .youtube { background-position: -768px -32px; }


/*-----------------------------------------------------------------------------------*/
/*	26.	Secondary Navigation Elements & Links
/*-----------------------------------------------------------------------------------*/

/* Link with long/small arrow pointer
--------------------------------------------------- */
a.link-lg, a.link-sm {
	display: inline-block;
	position: relative;
	font-family: Ubuntu, Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 13px;
	font-weight: normal;
	line-height: 15px;
	font-style: italic;
}
a.link-lg {
	background: url("/images/colored_theme/link-arrow-long-colored-1.png") no-repeat right 6px;
	padding: 0 19px 3px 0;
}
a.link-sm {
	background: url("/images/colored_theme/link-arrow-small-colored-1.png") no-repeat right 5px;
	padding: 0 10px 3px 0;
}
a.link-lg.black { background-image: url("/images/icons/link-arrow-long.png"); }
a.link-lg.colored-text-2 { background-image: url("/images/colored_theme/link-arrow-long-colored-2.png"); }
a.link-sm.black { background-image: url("/images/icons/link-arrow-small.png"); }
a.link-sm.colored-text-2 { background-image: url("/images/colored_theme/link-arrow-small-colored-2.png"); }
a.link-lg:hover {
	color: #e8554e !important;
	background-image: url("/images/colored_theme/link-arrow-long-colored-2.png");
}
a.link-sm:hover {
	color: #e8554e !important;
	background-image: url("/images/colored_theme/link-arrow-small-colored-2.png");
}
a.link-lg span, a.link-sm span {
	position: absolute;
	top: 0; left: 0; bottom: 0; right: 0;
	opacity: 0;
	border-bottom: 1px dotted #e8554e;
	-webkit-transition: opacity 0.15s ease;
	   -moz-transition: opacity 0.15s ease;
	     -o-transition: opacity 0.15s ease;
	        transition: opacity 0.15s ease;
}
a.link-lg span { margin-right: 19px; }
a.link-sm span { margin-right: 10px; }
a.link-lg:hover span, a.link-sm:hover span { opacity: 1; }

/* For Webkit browsers */
#page-content a.link-lg, #page-content a.link-sm,
#home-featured-content a.link-lg, #home-featured-content a.link-sm,
#page-title a.link-lg, #page-title a.link-sm {
	-webkit-text-stroke: 0.2px;
}
.touch-screen #page-content a.link-lg, .touch-screen #page-content a.link-sm,
.touch-screen #home-featured-content a.link-lg, .touch-screen #home-featured-content a.link-sm,
.touch-screen #page-title a.link-lg, .touch-screen #page-title a.link-sm {
	-webkit-text-stroke: 0;
}

/* For Internet Explorer 8 */
.ie8 a.link-lg { background-position: right 7px; }
.ie8 a.link-sm { background-position: right 6px; }
.ie8 a.link-lg span, .ie8 a.link-sm span { visibility: hidden; }
.ie8 a.link-lg:hover span, .ie8 a.link-sm:hover span { visibility: visible; }

/* Pagination
--------------------------------------------------- */
.pagination a, .pagination > span {
	display: block;
	float: left;
	width: 29px;
	height: 23px;
	font-size: 14px;
	line-height: 14px;
	font-weight: 600;
	color: #b1b9ad;
	border: 1px solid transparent;
	text-align: center;
	margin: 0 0 6px 4px;
	padding-top: 6px;
}
.pagination a:first-child, .pagination > span:first-child { margin-left: 0; }

.pagination .prev, .pagination .next {
	position: relative;
	background: url("/images/icons/slider-direction-nav.png") no-repeat;
}
.pagination .prev  { background-position: 9px -408px; margin-left: 0; }
.pagination .next { background-position: -50px -408px; }
.pagination a:hover {
	color: #fff;
	border-color: #b5b5b5;
	background-color: #b5b5b5;
}
.pagination a.prev span, .pagination a.next span {
	position: absolute;
	top: 0; left: 0; bottom: 0; right: 0;
	background: url("/images/icons/slider-direction-nav.png") no-repeat;
	opacity: 0;
	-webkit-transition: opacity 0.15s ease;
	   -moz-transition: opacity 0.15s ease;
	     -o-transition: opacity 0.15s ease;
	        transition: opacity 0.15s ease;
}
.pagination a.prev span { background-position: 9px -348px; }
.pagination a.next span { background-position: -50px -348px; }
.pagination a.prev:hover span, .pagination a.next:hover span { opacity: 1; }

.ie8 .pagination a.prev span, .ie8 .pagination a.next span { background: none; }
.ie8 .pagination a.prev:hover { background-position: 9px -348px; }
.ie8 .pagination a.next:hover { background-position: -50px -348px; }

.pagination span.current {
	color: #fff;
	border-color: #e8554e;
	background-color: #e8554e;
}
.pagination span.pages {
	width: auto;
	border: none;
	margin: -3px 0 0 30px;
	font-size: 13px;
	line-height: 22px;
	font-weight: 400;
	color: #b1b9ad;
}
.ie8 .pagination a, .ie8 .pagination > span { font-family: OpenSansSemibold, Arial, "Helvetica Neue", Helvetica, sans-serif; }
.ie8 .pagination span.pages { font-family: "Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif; }

/* List menu
--------------------------------------------------- */
ul.list-nav { margin-bottom: 5px; }
ul.list-nav li {
	margin-bottom: 0;
	padding: 0 !important;
	background-image: none !important;
}
ul.list-nav a {
	display: inline-block;
	color: inherit;
	padding: 3px 0;
}
ul.list-nav a:hover { color: #e8554e; }
ul.list-nav li.current a { font-weight: 600; color: #444; }
.ie8 ul.list-nav li.current a {
	font-family: OpenSansSemibold, Arial, "Helvetica Neue", Helvetica, sans-serif;
}
ul.list-nav.italic li.current a { color: #777; }
ul.list-nav.colored-text-1 li.current a { color: #444; font-weight: normal; }

ul.list-nav.arrow-list a {
	padding-left: 13px;
	background: url("/images/icons/list-marker-arrow.png") no-repeat 0 9px;
}
ul.list-nav.arrow-list.italic a {
	padding-left: 12px;
	background: url("/images/icons/list-marker-arrow-oblique.png") no-repeat 0 10px;
}
ul.list-nav.square-list a {
	padding-left: 13px;
	background: url("/images/icons/list-marker-square.png") no-repeat 0 11px;
}

ul.list-nav.arrow-list:not(.table) a:hover {
	background-image: url("/images/colored_theme/list-marker-arrow-colored.png");
}
ul.list-nav.arrow-list.italic:not(.table) a:hover {
	background-image: url("/images/colored_theme/list-marker-arrow-oblique-colored.png");
}
ul.list-nav.square-list:not(.table) a:hover {
	background-image: url("/images/colored_theme/list-marker-square-colored.png");
}

ul.list-nav.arrow-list li.current a:hover { background-image: url("/images/icons/list-marker-arrow.png"); }
ul.list-nav.arrow-list.italic li.current a:hover { background-image: url("/images/icons/list-marker-arrow-oblique.png"); }
ul.list-nav.square-list li.current a:hover { background-image: url("/images/icons/list-marker-square.png"); }

ul.list-nav span {
	color: #b1b9ad;
	margin-left: 5px;
	white-space: nowrap;
}
ul.list-nav br + span { margin-left: 0; }

/* Ruled menu */
ul.list-nav.ruled li {
	border-top: 1px dotted #c7cfc3;
}
ul.list-nav.ruled li:first-child { border-top: none; }
ul.list-nav.ruled a {
	display: block;
	padding: 7px 0;
}
ul.list-nav.ruled.arrow-list a {
	padding-left: 13px;
	background-position: 0 13px;
}
ul.list-nav.ruled.arrow-list.italic a {
	padding-left: 12px;
	background-position: 0 14px;
}
ul.list-nav.ruled.square-list a {
	padding-left: 13px;
	background-position: 0 15px;
}

/* Table menu */
ul.list-nav.table li {
	margin-top: 1px;
	background-color: #ebeee9;
}
ul.list-nav.table li:first-child { margin-top: 0; }
ul.list-nav.table li:hover,
ul.list-nav.table li.current {
	background-color: #e6e9e4;
	background: rgba(199, 207, 195, 0.4);
}
ul.list-nav.table a {
	display: block;
	padding: 9px 14px;
}
ul.list-nav.table a:hover, ul.list-nav.table a:hover * { color: #444; }
ul.list-nav.table li.current a { font-weight: normal; color: #444; }
.ie8 ul.list-nav.table li.current a {
	font-family: "Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif;
}
ul.list-nav.table.colored-text-1 a:hover,
ul.list-nav.table.colored-text-1 a:hover *,
ul.list-nav.table.colored-text-1 li.current a { color: #e8554e; }
ul.list-nav.table .fa { padding-right: 5px; }
ul.list-nav.table.arrow-list a {
	padding-left: 27px;
	background-position: 14px 15px;
}
ul.list-nav.table.arrow-list.italic a {
	padding-left: 26px;
	background-position: 14px 16px;
}
ul.list-nav.table.square-list a {
	padding-left: 27px;
	background-position: 14px 17px;
}

/* specific styles for screens with 768 pixels wide and higher (overwrite the basic styles) */
@media only screen and (min-width: 768px) {
	ul.list-nav.arrow-list a { background-position: 0 11px; }
	ul.list-nav.arrow-list.italic a { background-position: 0 12px; }
	ul.list-nav.square-list a { background-position: 0 13px; }
	ul.list-nav.ruled.arrow-list a { background-position: 0 15px; }
	ul.list-nav.ruled.arrow-list.italic a { background-position: 0 16px; }
	ul.list-nav.ruled.square-list a { background-position: 0 17px; }
	ul.list-nav.table.arrow-list a { background-position: 14px 17px; }
	ul.list-nav.table.arrow-list.italic a { background-position: 14px 18px; }
	ul.list-nav.table.square-list a { background-position: 14px 19px; }
}

/* Feature menu
--------------------------------------------------- */
ul.feature-nav { margin-bottom: 0; }
ul.feature-nav li { margin: 10px 0 0; }
ul.feature-nav li:first-child { margin-top: 0; }
ul.feature-nav a {
	display: block;
	background-color: #e6e9e4;
	padding: 7px 25px 10px;
}
ul.feature-nav a:hover { background-color: rgba(199, 207, 195, 0.5); }
ul.feature-nav a > span {
	display: block;
	float: left;
	margin-top: 3px;
}
ul.feature-nav a > span .fa { /* vector icons - Font Awesome */
	font-size: 30px;
	line-height: 30px;
	color: #e8554e;
}
ul.feature-nav a > div {
	color: #777;
	margin-left: 50px;
}

ul.feature-nav.inline li {
	float: left;
	margin: 0 10px 10px 0;
}
ul.feature-nav.inline li:last-child { margin-right: 0; }


/*-----------------------------------------------------------------------------------*/
/*	28. Contact + Form + Google Map
/*-----------------------------------------------------------------------------------*/

/* Form
--------------------------------------------------- */
.form { overflow: hidden; }
.form div { margin-left: 2px; }

.form input[type="text"],
.form input[type="email"] {
	width: 70%;
}
.form textarea {
	width: 95%;
	height: 150px;
}
.form select { width: 72%; }
.form input[type="checkbox"] { margin-right: 5px; }
.form input[type="submit"] { margin-right: 20px; }
.form #submit-button { margin-top: 20px; }
.form #submit-button img { display: inline; }

.form label { margin-bottom: 2px; }
.form .checkbox-field label {
	display: inline;
	margin-bottom: 0;
}
.form label span { color: #ff4444; }
.form input[type="text"].error,
.form input[type="email"].error,
.form textarea.error,
.form select.error {
	border-color: #ffaaaa;
}

.form .message-box { margin: 25px 0 0; }

/* Contact Information
--------------------------------------------------- */
.contact-info .fa {
	float: left;
	color: #e8554e;
}
.contact-info [class^="contact-"] > div { margin-left: 30px; }
.contact-info [class^="contact-"] p { margin-bottom: 0; }

.contact-address .fa {
	font-size: 24px;
	margin-top: 1px;
}
.contact-phone .fa {
	font-size: 24px;
	margin-left: 3px;
}
.contact-fax .fa {
	font-size: 17px;
	margin-top: 2px;
}
.contact-email .fa {
	font-size: 16px;
	margin-top: 2px;
}

.working-hours { width: 200px; }
.working-hours li { margin-bottom: 0; }
.working-hours .fa {
	font-size: 14px;
	margin: 3px 0 0 3px;
}
.working-hours li > div { margin-left: 23px; }
.working-hours li > div p { margin-bottom: 0; }
.working-hours li > div span { float: right; font-style: italic; }
.ie8 .working-hours > li div span { font-family: OpenSansItalic, Arial, "Helvetica Neue", Helvetica, sans-serif; }

#sidebar .working-hours { width: auto; }
#sidebar .working-hours li > div span { float: none; font-style: normal; }
.ie8 #sidebar .working-hours > li div span { font-family: "Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif; }

.contact-info .ci-title {
	font-size: 12px;
	line-height: 19px;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: 3px;
}
.ie8 .contact-info .ci-title {
	font-family: OpenSansSemibold, Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.contact-phone > div *,
.contact-fax > div *,
.phone-number * {
	color: inherit !important; /* overwrite style applied by iOS for automatically detected phone numbers */
}
span.phone-number { white-space: nowrap; }

/* Google Maps
--------------------------------------------------- */
.google-map {
	width: 100%;
	height: 400px;
	background-color: #f8f9f4 !important;
}
.google-map > span {
	display: block;
	font-size: 12px;
	line-height: 19px;
	text-align: center;
	padding-top: 20px;
}
.google-map .gm-title {
	font-size: 13px;
	line-height: 22px;
	color: #333;
	font-weight: 600;
	margin-bottom: 3px;
}
.google-map p {
	color: #333;
	line-height: 19px;
	margin-bottom: 7px;
}
#main-content.adaptive-map .google-map { position: relative; }

/* Contact Form
--------------------------------------------------- */
.contact-form-wrapper {
	margin-right: 25px;
	padding-right: 40px;
	background: url("/images/backgrounds/contact-info-divider.png") no-repeat right top;
	min-height: 500px;
}
#main-content .contact-form-wrapper {
	margin: 0;
	padding: 0;
	background: none;
	min-height: 0;
}
#main-content.adaptive-map .contact-form-wrapper { margin-bottom: 50px; }

/* specific styles for screens with 768 pixels wide and higher (overwrite the basic styles) */
@media only screen and (min-width: 768px) {
	.contact-address .fa { font-size: 26px; margin-top: 2px; }
	.contact-phone .fa { font-size: 26px; }
	.contact-fax .fa { font-size: 19px; margin-top: 3px; }
	.contact-email .fa { font-size: 18px; margin-top: 3px; }
	.working-hours .fa { font-size: 16px; margin-top: 4px; }
	.working-hours { width: 220px; }
	.working-hours li > div { margin-left: 26px; }
	
	#main-content.adaptive-map .columns { position: relative; }
	#main-content.adaptive-map .google-map {
		top: 0;
		left: 0;
		height: 400px;
	}
	#main-content.adaptive-map .contact-form-wrapper {
		position: relative;
		left: 0;
		margin-bottom: 50px;
	}
}


/*-----------------------------------------------------------------------------------*/
/*	29. Other Elements
/*-----------------------------------------------------------------------------------*/

/* List of Logos
--------------------------------------------------- */
.list-of-logos { margin: 0; }
.list-of-logos li {
	float: left;
	list-style: none;
	padding: 5px 0;
	margin: 0;
	width: 176px;
	text-align: center;
}
.list-of-logos li a {
	display: block;
	padding: 5px 0;
	margin: -5px 0;
}
.list-of-logos img {
	display: inline-block;
	opacity: 0.7;
	-webkit-transition: opacity 0.2s ease;
	   -moz-transition: opacity 0.2s ease;
	     -o-transition: opacity 0.2s ease;
	        transition: opacity 0.2s ease;
}
.list-of-logos li:hover img { opacity: 1; }

/* Standard Table
--------------------------------------------------- */
table.standard {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	margin-bottom: 20px;
}
table.standard th {
	font-weight: bold;
	border: 1px solid #c7cfc3;
	border-bottom: 1px solid #b1b9ad;
	background-color: #ebeee9;
	padding: 8px 15px;
	text-align: left;
	vertical-align: top;
}
table.standard td {
	border: 1px solid #c7cfc3;
	padding: 8px 15px;
	vertical-align: top;
}
table.standard tr:hover td {
	background-color: #f0f2ed;
	background-color: rgba(199, 207, 195, 0.1);
}
.ie8 table.standard th { font-family: OpenSansBold, Arial, "Helvetica Neue", Helvetica, sans-serif; }

/* specific styles for screens with 768 pixels wide and higher (overwrite the basic styles) */
@media only screen and (min-width: 768px) {
	.white-bg table.standard th {
		border: 1px solid #dedede;
		border-bottom: 1px solid #b5b5b5;
		background-color: #f2f2f2;
	}
	.white-bg table.standard td { border: 1px solid #dedede; }
	.white-bg table.standard tr:hover td { background-color: #fbfbfb; }
}


/*-----------------------------------------------------------------------------------*/
/*	30. Typography
/*-----------------------------------------------------------------------------------*/

/* Dropcap
--------------------------------------------------- */
.dropcap:first-letter {
	float: left;
	font-family: Georgia;
	font-size: 54px;
	line-height: 54px;
	margin: 8px 8px 4px 0;
}
.dropcap.colored:first-letter { color: #8c9560; }
.ie8 .dropcap:first-letter { margin-top: 0; }

/* Highlights
--------------------------------------------------- */

.highlight { padding: 0 3px 1px; }
.highlight.dark { color: #fff; background-color: #727378; }
.highlight.dotted { color: #666; background: none; border-bottom: 1px dotted #e8554e; padding: 0 2px; }
.highlight.colored { color: #fff; background-color: #e8554e; }

/* Lists
--------------------------------------------------- */
.check-list, .arrow-list, .dash-list, .square-list, .circle-list {
	display: block;
	list-style-type: none;
	padding: 0;
	overflow: hidden;
}
.check-list li {
	padding-left: 22px;
	margin-bottom: 5px;
	background: url("/images/icons/list-marker-check.png") no-repeat 0 5px;
}
.arrow-list li {
	padding-left: 13px;
	margin-bottom: 5px;
	background: url("/images/icons/list-marker-arrow.png") no-repeat 0 6px;
}
.arrow-list.italic li {
	padding-left: 12px;
	background: url("/images/icons/list-marker-arrow-oblique.png") no-repeat 0 7px;
}
.dash-list li {
	padding-left: 17px;
	margin-bottom: 3px;
	background: url("/images/icons/list-marker-dash.png") no-repeat 0 9px;
}
.square-list li {
	padding-left: 13px;
	margin-bottom: 3px;
	background: url("/images/icons/list-marker-square.png") no-repeat 0 8px;
}
.circle-list li {
	padding-left: 13px;
	margin-bottom: 3px;
	background: url("/images/icons/list-marker-circle.png") no-repeat 0 8px;
}
.check-list.colored li { background-image: url("/images/colored_theme/list-marker-check-colored.png"); }
.arrow-list.colored li { background-image: url("/images/colored_theme/list-marker-arrow-colored.png"); }
.arrow-list.italic.colored li { background-image: url("/images/colored_theme/list-marker-arrow-oblique-colored.png"); }
.dash-list.colored li { background-image: url("/images/colored_theme/list-marker-dash-colored.png"); }
.square-list.colored li { background-image: url("/images/colored_theme/list-marker-square-colored.png"); }
.circle-list.colored li { background-image: url("/images/colored_theme/list-marker-circle-colored.png"); }

.check-list.tight li,
.arrow-list.tight li,
.dash-list.tight li,
.square-list.tight li,
.circle-list.tight li { margin-bottom: 0; }

.check-list.middle-font-size li { background-position: 0 8px; }
.arrow-list.middle-font-size li { background-position: 0 9px; }
.dash-list.middle-font-size li { background-position: 0 12px; }
.square-list.middle-font-size li { background-position: 0 11px; }
.circle-list.middle-font-size li { background-position: 0 11px; }

/* specific styles for screens with 768 pixels wide and higher (overwrite the basic styles) */
@media only screen and (min-width: 768px) {
	.check-list li { background-position: 0 7px; }
	.arrow-list li { background-position: 0 8px; }
	.arrow-list.italic li { background-position: 0 9px; }
	.dash-list li { background-position: 0 11px; }
	.square-list li { background-position: 0 10px; }
	.circle-list li { background-position: 0 10px; }
	
	.check-list.small-font-size li { background-position: 0 5px; }
	.arrow-list.small-font-size li { background-position: 0 6px; }
	.arrow-list.italic.small-font-size li { background-position: 0 7px; }
	.dash-list.small-font-size li { background-position: 0 9px; }
	.square-list.small-font-size li { background-position: 0 8px; }
	.circle-list.small-font-size li { background-position: 0 8px; }
	
	.check-list.middle-font-size li { background-position: 0 9px; }
	.arrow-list.middle-font-size li { background-position: 0 10px; }
	.dash-list.middle-font-size li { background-position: 0 13px; }
	.square-list.middle-font-size li { background-position: 0 12px; }
	.circle-list.middle-font-size li { background-position: 0 12px; }
}


/*-----------------------------------------------------------------------------------*/
/*	31. Misc.
/*-----------------------------------------------------------------------------------*/

/* HTML5 video (inline player)
--------------------------------------------------- */
.html5-video-container {
	position: relative;
	max-width: 100%;
}
.html5-video-container .player {
	position: absolute;
	left: 0; top: 0; width: 100%; height: 100%;
}

.video-container iframe {
	border: none;
}

/* Social network plugins
--------------------------------------------------- */
ul.social-share { margin-bottom: 0; }
ul.social-share li {
	float: left;
	margin-right: 5px;
}
ul.social-share li:last-child { margin-right: 0; }
ul.social-share li.google { width: 70px; }
ul.social-share li.twitter { width: 90px; }
ul.social-share li.facebook { width: 85px; }

.ie8 ul.social-share li.google { display: none; }

/* Facebook Like Box plugin */
#sidebar.page-right-col .fb-like-box {
	margin-left: -8px;
	margin-right: -7px;
}
#sidebar.page-right-col .fb-like-box > span,
#sidebar.page-right-col .fb-like-box iframe { width: 235px !important; }
#sidebar.page-left-col .fb-like-box {
	margin-left: -8px;
	margin-right: -12px;
}
#sidebar.page-left-col .fb-like-box > span,
#sidebar.page-left-col .fb-like-box iframe { width: 180px !important; }

/* Other
--------------------------------------------------- */
.error-404 {
	font-size: 220px;
	line-height: 220px;
	color: #b1b9ad;
	text-align: center;
	opacity: 0.4;
}
.ie8 .error-404 { color: #e6e9e4; }

.grid-col {
	background-color: #ebeee9;
	padding: 5px 0;
	margin-bottom: 2px;
	text-align: center;
}


/*-----------------------------------------------------------------------------------*/
/*	32. Media Queries
/*-----------------------------------------------------------------------------------*/

/* Tablet Landscape size + Desktop & Laptop 1024 pixels wide
---------------------------------------------------------------------- */
@media only screen and (min-width: 960px) and (max-width: 1199px) {
	
	/* For page layout with sidebar */
	#main-content.page-left-col { width: 696px; padding-right: 30px; }
	#main-content.page-right-col { width: 744px; padding-left: 30px; }
	#sidebar.page-left-col { width: 152px; padding-right: 24px; }
	#sidebar.page-left-col .widget { width: 152px; }
	#sidebar.page-right-col { width: 200px; padding-left: 24px; }
	#sidebar.page-right-col .widget { width: 200px; }
	/* end */
	
	#primary-nav .sf-mega { max-width: 918px; }
	
	#home-hero-content { height: 413px; }
	.hero-image { margin-left: 230px; }
	.hero-image img, #home-hero-content .slides img  { width: 1120px; }
	
	#home-hero-nav .columns { top: 465px; }
	.slider-menu { left: 330px; margin-right: 0; }
	.slider-menu.thumbnails { left: 320px; margin-right: 0; }
	.slider-menu.thumbnails li { margin-left: 14px; }
	#home-hero-nav .slider-caption { left: 370px; max-width: 570px; }
	
	.intro-wrapper { left: -190px; top: -95px; }
	.intro-content { padding: 125px 50px 0 190px; }
	.intro-content .slider-caption > div { padding-right: 60px; }
	.intro-content .preview-block > [class^="thumbnail"] { margin-left: 0; }
	
	#home-featured-content .grid-bg { padding-top: 80px; }
	
	#page-title.custom-bg { height: 400px; }
	
	#home-slider.right-side-nav .flex-direction-nav .flex-prev { top: 150px; }
	#home-slider.right-side-nav .flex-direction-nav .flex-next { top: 205px; }
	
	.feature-boxes.medium-col-space { margin: 0 -12px; }
	.feature-boxes.medium-col-space .columns { margin-left: 14px; margin-right: 14px; }
	.feature-boxes.medium-col-space article { margin-left: 8px; margin-right: 8px; }
	.feature-boxes.small-col-space { margin: 0 -8px; }
	.feature-boxes.small-col-space .columns { margin-left: 12px; margin-right: 12px; }
	.feature-boxes.small-col-space article { margin-left: 6px; margin-right: 6px; }
	
	#portfolio-wrapper.large-col-space { margin: 0 -10px; }
	#portfolio-wrapper.large-col-space .columns { margin-left: 15px; margin-right: 15px; }
	#portfolio-wrapper.large-col-space article { margin: 0 5px; }
	#portfolio-wrapper.medium-col-space { margin: 0 -7px; }
	#portfolio-wrapper.medium-col-space .columns { margin-left: 12px; margin-right: 12px; }
	#portfolio-wrapper.medium-col-space article { margin: 0 5px; }
	#portfolio-wrapper.small-col-space { margin: 0 -4px; }
	#portfolio-wrapper.small-col-space article { margin: 0 3px; }
	#portfolio-wrapper.small-col-space .item-description { padding-left: 15px; padding-right: 15px; }
	
	#projects-carousel.three-slides li.portfolio-item-preview { width: 300px; }
	#projects-carousel.four-slides li.portfolio-item-preview,
	#projects-carousel.two-slides li.portfolio-item-preview { width: 220px; }
	#main-content.page-left-col #projects-carousel.three-slides li.portfolio-item-preview,
	#main-content.page-left-col #projects-carousel.four-slides li.portfolio-item-preview { width: 212px; }
	#main-content.page-right-col #projects-carousel.three-slides li.portfolio-item-preview,
	#main-content.page-right-col #projects-carousel.four-slides li.portfolio-item-preview { width: 228px; }
	
	.gallery-wrapper.masonry .s1 .item-picture { width: 300px; height: 211px; }
	.gallery-wrapper.masonry .s2 .item-picture { width: 300px; height: 442px; }
	.gallery-wrapper.masonry .s4 .item-picture { width: 620px; height: 442px; }
	.gallery-wrapper.masonry .item-picture[data-type=text] div { padding: 15px 25px; }
	
	.single-item-details .extra-content #portfolio-wrapper.small-col-space .columns { margin-left: 33px; }
	
	.team.two-cols { margin: 0 -10px; }
	.team.two-cols .columns { margin-left: 15px; margin-right: 15px; }
	.team.two-cols article { margin-left: 5px; margin-right: 5px; }
	.team.three-cols { margin: 0 -12px; }
	.team.three-cols .columns { margin-left: 14px; margin-right: 14px; }
	.team.three-cols article { margin-left: 8px; margin-right: 8px; }
	.team.four-cols { margin: 0 -8px; }
	.team.four-cols .columns { margin-left: 12px; margin-right: 12px; }
	.team.four-cols article { margin-left: 6px; margin-right: 6px; }
	#main-content .team.four-cols,
	#main-content .team.four-cols article { margin-left: 0; margin-right: 0; }
	#main-content .team.four-cols .columns { margin-left: 10px; margin-right: 10px; }
	
	#footer .contact-description { width: 260px; }
	#footer input[type="email"] { width: 177px; }
	#info-share input[type="email"] { width: 200px; }
	.ie8 #info-share #subscription-form,
	#info-share .footer-message { width: 243px; }
	
	#footer .flickr-widget { margin: 0 -12px -12px 0; }
	#footer .flickr-widget .flickr_badge_image { margin: 0 12px 12px 0; }
	
	#sidebar.page-left-col .side-menu { margin-right: -23px; }
	#sidebar.page-right-col .side-menu { margin-left: -23px; }
	#sidebar.page-left-col .side-menu li a { padding-right: 23px; }
	#sidebar.page-right-col .side-menu li a { padding-left: 23px; }
	#sidebar.page-left-col .side-menu li.current a { padding-right: 24px; }
	#sidebar.page-right-col .side-menu li.current a { padding-left: 24px; }
	
	.blog-grid-style { margin: 0 -7px; }
	.blog-grid-style .columns { margin-left: 12px; margin-right: 12px; }
	.blog-grid-style article { margin: 0 5px; }
	.blog-grid-style .post-content { height: 176px; }
	
	.list-of-logos li { width: 188px; }
	
	#sidebar.page-right-col .fb-like-box > span,
	#sidebar.page-right-col .fb-like-box iframe { width: 215px !important; height: 300px !important; }
	#sidebar.page-left-col .fb-like-box > span,
	#sidebar.page-left-col .fb-like-box iframe { width: 150px !important; height: 300px !important; }
}

/* Screens that are more than 959 pixels wide (devices and browsers)
---------------------------------------------------------------------- */
@media only screen and (min-width: 960px) {
	
}

/* Smaller than 960 pixels wide (devices and browsers)
---------------------------------------------------------------------- */
@media only screen and (max-width: 959px) {
	.intro-content .divider-pattern:last-child,
	.intro-content .divider-line:last-child,
	.intro-content .divider-dashline:last-child	{ display: none; }
	
	#page-title .grid-bg { padding: 20px 0 28px 0; background-position: 0 18px; }
	.breadcrumb-nav { float: none; margin: 0 0 10px; }
	
	.feature-boxes.small-col-space,
	.feature-boxes.small-col-space article { margin-left: 0; margin-right: 0; }
	
	#portfolio-wrapper.medium-col-space,
	#portfolio-wrapper.medium-col-space article,
	#portfolio-wrapper.small-col-space,
	#portfolio-wrapper.small-col-space article { margin: 0; }
	
	#portfolio-wrapper.large-col-space .item-description h3,
	#portfolio-wrapper.medium-col-space .item-description h3,
	#portfolio-wrapper.small-col-space .item-description h3 { /* h3 >> h4 */
		font-size: 16px;
		line-height: 24px;
		color: #444;
		font-weight: 600;
	}
	#portfolio-wrapper.long-description h4 { font-size: 16px; line-height: 24px; }
	
	.team.four-cols,
	.team.four-cols article { margin-left: 0; margin-right: 0; }
	
	#footer-bottom .footer-nav a { padding-right: 25px; }
	
	.blog-grid-style,
	.blog-grid-style article { margin: 0; }
	
	.announcements section.adapted-max-959px article:first-child {
		border-top: 1px dotted #c7cfc3; margin-top: 25px; padding-top: 20px;
	}
	.announcements section.adapted-max-959px:first-child article:first-child {
		border: none; margin-top: 0; padding-top: 0;
	}
	
	.pricing-table.extended .features-list { display: none; }
	.pricing-table.extended .features li:before {
		content: attr(data-feature) ':';
		display: block;
		font-weight: bold;
		text-align: center;
	}
	.pricing-table.extended .features-list + .table-column .header { border-left: none; }
	.pricing-table.extended .features-list + .table-column .price,
	.pricing-table.extended .features-list + .table-column .features { border-left: 1px solid #e6e9e4; }
}

/* Tablet Portrait size (devices and browsers)
---------------------------------------------------------------------- */
@media only screen and (min-width: 768px) and (max-width: 959px) {
	
	/* For page layout with sidebar */
	#main-content.page-left-col { width: 516px; padding-right: 22px; }
	#main-content.page-right-col { width: 564px; padding-left: 22px; }
	#sidebar.page-left-col { width: 152px; padding-right: 20px; }
	#sidebar.page-left-col .widget { width: 152px; }
	#sidebar.page-right-col { width: 200px; padding-left: 20px; }
	#sidebar.page-right-col .widget { width: 200px; }
	/* end */
	
	#primary-nav .sf-mega { max-width: 726px; }
	
	#home-hero-content { height: 413px; }
	#home-hero-content .columns { width: 100%; margin: 0; }
	#home-hero { width: 100%; }
	.hero-image { margin-left: 0; }
	.hero-image img, #home-hero-content .slides img  { width: 1120px; }
	
	#home-hero-nav .columns { top: 425px; }
	.slider-menu { left: 85px; margin: 0; }
	.slider-menu.thumbnails { margin: 0; }
	.slider-menu.thumbnails li { margin-left: 12px; width: 130px; }
	#home-hero-nav .slider-caption { left: 74px; bottom: 30px; max-width: 600px; }
	
	#home-intro { position: static; margin: 30px 0 20px; }
	.intro-wrapper {
		position: static;
		left: auto; top: auto; width: auto; height: auto;
		background: none !important;
	}
	.intro-wrapper:before { display: none; }
	.intro-content { padding: 0; }
	.intro-content h1 { margin-bottom: 25px !important; }
	.intro-content .preamble {
		float: left;
		width: 396px;
		padding-right: 30px;
	}
	.intro-content.caption-inside .preamble { width: 468px; }
	.intro-content .preview-block {
		float: right;
		width: 300px;
		background-color: #f0f2ed;
		border: 1px solid #e6e9e4;
		padding: 10px 10px 20px;
		margin-bottom: 20px;
	}
	.intro-content .preview-block > [class^="thumbnail"] { margin-left: 0; }
	.intro-content .slider-caption {
		position: static;
		float: right;
		width: 250px;
		margin-top: 10px;
	}
	.intro-content .slider-caption > div { padding-right: 0; }
	.intro-content.caption-inside .slider-caption > div { width: 250px; }
	
	#home-featured-content .grid-bg { padding: 35px 0 20px 0; }
	#home-featured-content .feature-boxes.left-icon-box { margin-top: 0; }
	
	#page-title.custom-bg { height: 350px; }
	
	#home-slider.right-side-nav .flex-direction-nav .flex-prev { top: 150px; }
	#home-slider.right-side-nav .flex-direction-nav .flex-next { top: 205px; }
	
	.feature-boxes.medium-col-space { margin: 0 -6px; }
	.feature-boxes.medium-col-space .columns { margin-left: 12px; margin-right: 12px; }
	.feature-boxes.medium-col-space article { margin-left: 4px; margin-right: 4px; }
	.feature-boxes.small-col-space .columns { margin-left: 10px; margin-right: 10px; }
	.feature-boxes h3 { font-size: 19px; line-height: 26px; }
	
	#main-content.page-left-col .feature-boxes.left-icon-box .four.columns,
	#main-content.page-left-col .feature-boxes.left-icon-box .one-third.columns { width: 496px; }
	#main-content.page-right-col .feature-boxes.left-icon-box .four.columns,
	#main-content.page-right-col .feature-boxes.left-icon-box .one-third.columns { width: 544px; }
	#main-content.page-left-col .feature-boxes.top-icon-box .three.columns,
	#main-content.page-left-col .feature-boxes.top-icon-box .one-fourth.columns { width: 496px; }
	#main-content.page-right-col .feature-boxes.top-icon-box .three.columns,
	#main-content.page-right-col .feature-boxes.top-icon-box .one-fourth.columns { width: 544px; }
	#main-content .feature-boxes.left-icon-box .four.columns article,
	#main-content .feature-boxes.left-icon-box .one-third.columns article,
	#main-content .feature-boxes.top-icon-box .three.columns article,
	#main-content .feature-boxes.top-icon-box .one-fourth.columns article { margin-top: 30px !important; }
	#main-content .feature-boxes .row:first-child .columns:first-child article { margin-top: 0 !important; }
	
	#portfolio-wrapper.large-col-space { margin: 0 -7px; }
	#portfolio-wrapper.large-col-space article { margin: 0 4px; }
	#portfolio-wrapper.large-col-space .columns { margin-left: 13px; margin-right: 13px; }
	#portfolio-wrapper.medium-col-space .columns,
	#portfolio-wrapper.small-col-space .columns { margin-left: 10px; margin-right: 10px; }
	#main-content #portfolio-wrapper.one-col .portfolio-item-preview { margin-bottom: 30px; }
	#main-content.page-left-col #portfolio-wrapper.one-col .portfolio-item-preview .columns { width: 496px; margin: 0; }
	#main-content.page-right-col #portfolio-wrapper.one-col .portfolio-item-preview .columns { width: 544px; margin: 0; }
	#main-content #portfolio-wrapper.one-col .item-description { margin: 20px 0 0; }
	#main-content #portfolio-wrapper.one-col .extra-tags { margin: 0; }
	#portfolio-wrapper.medium-col-space .item-description { padding-left: 15px; padding-right: 15px; }
	#portfolio-wrapper.small-col-space .item-description { padding-left: 10px; padding-right: 10px; }
	#portfolio-wrapper.small-col-space .item-description h6,
	#projects-carousel.two-slides .item-description h6 {
		font: 15px/22px "Roboto Condensed", "Franklin Gothic Medium", Tahoma, sans-serif;
		font-weight: 300;
	}
	.portfolio-item-preview .item-description h4 { font-size: 15px; line-height: 23px; } /* h4 >> h5 */
	.portfolio-item-preview .item-description h5 { font-size: 14px; line-height: 22px; } /* h5 >> h6 */
	.portfolio-item-preview .extra-tags > span { display: block; }
	.portfolio-item-preview .extra-tags .details { margin-left: 0; }
	#portfolio-wrapper.medium-col-space .item-description + .extra-tags { padding-left: 15px; padding-right: 15px; }
	#portfolio-wrapper.small-col-space .item-description + .extra-tags { padding-left: 10px; padding-right: 10px; }
	
	#projects-carousel.three-slides li.portfolio-item-preview,
	#projects-carousel.four-slides li.portfolio-item-preview { width: 236px; }
	#projects-carousel.two-slides li.portfolio-item-preview { width: 172px; }
	#main-content.page-left-col #projects-carousel.three-slides li.portfolio-item-preview,
	#main-content.page-left-col #projects-carousel.four-slides li.portfolio-item-preview { width: 238px; }
	#main-content.page-right-col #projects-carousel.three-slides li.portfolio-item-preview,
	#main-content.page-right-col #projects-carousel.four-slides li.portfolio-item-preview { width: 262px; }
	
	.gallery-wrapper.four-cols .three.columns,
	.gallery-wrapper.four-cols .one-fourth.columns { width: 236px; }
	.gallery-wrapper.four-cols .six.columns,
	.gallery-wrapper.four-cols .one-half.columns { width: 492px; }
	#main-content.page-left-col .gallery-wrapper.four-cols .three.columns,
	#main-content.page-left-col .gallery-wrapper.four-cols .one-fourth.columns { width: 152px; }
	#main-content.page-left-col .gallery-wrapper.four-cols .six.columns,
	#main-content.page-left-col .gallery-wrapper.four-cols .one-half.columns { width: 324px; }
	#main-content.page-right-col .gallery-wrapper.four-cols .three.columns,
	#main-content.page-right-col .gallery-wrapper.four-cols .one-fourth.columns { width: 168px; }
	#main-content.page-right-col .gallery-wrapper.four-cols .six.columns,
	#main-content.page-right-col .gallery-wrapper.four-cols .one-half.columns { width: 356px; }
	
	.gallery-wrapper.masonry .four.columns,
	.gallery-wrapper.masonry .one-third.columns { width: 364px; }
	.gallery-wrapper.masonry .eight.columns,
	.gallery-wrapper.masonry .two-thirds.columns { width: 748px; }
	.gallery-wrapper.masonry .s1 .item-picture { width: 364px; height: 256px; }
	.gallery-wrapper.masonry .s2 .item-picture { width: 364px; height: 532px; }
	.gallery-wrapper.masonry .s4 .item-picture { width: 748px; height: 532px; }
	
	.single-item-details .extra-content #portfolio-wrapper.small-col-space .columns { margin-left: 25px; }
	.container #main-content.page-left-col .single-item-details .columns { width: 496px; }
	.container #main-content.page-right-col .single-item-details .columns { width: 544px; }
	#main-content .single-item-details .one-third.columns,
	#main-content .single-item-details .four.columns { float: left; }
	#main-content .single-item-details .info-board { margin: 20px 0 0; }
	#main-content .single-item-details .info-board.colored { margin: 20px 0 0; padding: 0; background: none; }
	#main-content .single-item-details .text-description {
		margin-top: 30px;
		padding: 30px 0 0 !important;
		border-top: 1px dotted #c7cfc3;
	}
	#main-content .single-item-details .extra-content { margin: 30px 0 0; }
	
	.team.two-cols { margin: 0 -7px; }
	.team.two-cols .columns { margin-left: 13px; margin-right: 13px; }
	.team.two-cols article { margin-left: 4px; margin-right: 4px; }
	.team.three-cols { margin: 0 -6px; }
	.team.three-cols .columns { margin-left: 12px; margin-right: 12px; }
	.team.three-cols article { margin-left: 4px; margin-right: 4px; }
	.team.four-cols .columns { margin-left: 10px; margin-right: 10px; }
	#main-content .team.three-cols,
	#main-content .team.three-cols article { margin-left: 0; margin-right: 0; }
	#main-content .team.three-cols .columns { margin-left: 10px; margin-right: 10px; }
	#main-content.page-right-col .team.four-cols { margin: 0 -7px; }
	#main-content.page-right-col .team.four-cols .columns { margin-left: 13px; margin-right: 13px; width: 262px; }
	#main-content.page-right-col .team.four-cols article { margin-left: 4px; margin-right: 4px; margin-top: 30px; }
	#main-content.page-right-col .team.four-cols .row:first-child .columns:nth-child(1) article,
	#main-content.page-right-col .team.four-cols .row:first-child .columns:nth-child(2) article { margin-top: 0; }
	
	#footer .column-divider-left { margin-left: 10px; padding-left: 30px; }
	#footer .column-divider-right { margin-right: 10px; padding-right: 30px; }
	#footer .contact-data > div { padding: 0 10px; }
	#footer .contact-description { width: 164px; }
	#footer .contact-description > div { padding-left: 25px; }
	#footer input[type="email"] { width: 129px; }
	#info-share input[type="email"] { width: 145px; }
	.ie8 #info-share #subscription-form,
	#info-share .footer-message { width: 188px; }
	#footer-bottom .columns { width: 748px; }
	#footer-bottom .links { float: none; margin-top: 10px; }
	
	#sidebar.page-left-col .side-menu { margin-right: -19px; }
	#sidebar.page-right-col .side-menu { margin-left: -19px; }
	#sidebar.page-left-col .side-menu li a { padding-right: 19px; }
	#sidebar.page-right-col .side-menu li a { padding-left: 19px; }
	#sidebar.page-left-col .side-menu li.current a { padding-right: 20px; }
	#sidebar.page-right-col .side-menu li.current a { padding-left: 20px; }
	
	.blog.medium-size .post-media { width: 220px; }
	.blog.medium-size .post-content { margin-left: 240px; }
	.blog-grid-style .columns { margin-left: 10px; margin-right: 10px; }
	.blog-grid-style .post-content { height: 176px; }
	.blog-grid-style .post-content h3,
	.blog-grid-style .post-content h4 { /* h3, h4 >> h5 */
		font-size: 15px;
		line-height: 23px;
		color: #444;
		font-weight: 600;
	}
	.ie8 .blog-grid-style .post-content h3 { font-family: OpenSansSemibold, Arial, "Helvetica Neue", Helvetica, sans-serif; }
	.blog-grid-style .post-content p { font-size: 12px; line-height: 19px; }
	.comment .children { padding-left: 70px; }
	
	ul.feature-nav.inline.adapted-nav-left { float: left; margin-right: 30px; }
	ul.feature-nav.inline.adapted-nav-right { float: right; margin-left: 30px; }
	ul.feature-nav.inline.adapted-nav-left li,
	ul.feature-nav.inline.adapted-nav-right li { float: none; margin-right: 0; }
	
	.pricing-table.four-cols h3.price,
	.pricing-table.five-cols h3.price { font-size: 24px; line-height: 24px; }
	.pricing-table.extended.two-cols .table-column { width: 50%; }
	.pricing-table.extended.three-cols .table-column { width: 33.333333%; }
	.pricing-table.extended.four-cols .table-column { width: 25%; }
	.pricing-table.extended.five-cols .table-column { width: 20%; }
	
	.list-of-logos li { width: 187px; }
	
	#sidebar.page-right-col .fb-like-box > span,
	#sidebar.page-right-col .fb-like-box iframe { width: 215px !important; height: 300px !important; }
	#sidebar.page-left-col .fb-like-box > span,
	#sidebar.page-left-col .fb-like-box iframe { width: 150px !important; height: 300px !important; }
}

/* Screens that are more than 767 pixels wide (devices and browsers)
---------------------------------------------------------------------- */
@media only screen and (min-width: 768px) {
	#home-hero-nav .slider-caption > div * { color: #fff; }
	
	#portfolio-wrapper.one-line .portfolio-item-preview,
	#portfolio-wrapper.one-line.no-bg .portfolio-item-preview { margin-bottom: 0; }
	
	.blog-grid-style.one-line .post-preview { margin-bottom: 0; }
	
	#sidebar.page-left-col .flickr-widget,
	#sidebar.page-left-col .preview-images-list { margin: 0 -15px -15px 0; }
	#sidebar.page-left-col .flickr-widget .flickr_badge_image,
	#sidebar.page-left-col .preview-images-list a {
		width: 60px;
		margin: 0 15px 15px 0;
		padding: 2px;
	}
	#sidebar.page-left-col .flickr-widget .flickr_badge_image { height: 60px; }
}

/* All Mobile Sizes (devices and browser)
---------------------------------------------------------------------- */
@media only screen and (max-width: 767px) {
	h1.slogan { font-size: 30px; line-height: 37px; }
	#header { padding-bottom: 20px; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
	#top-bar .columns > div:first-child + div { margin-top: 10px; }
	#logo {
		position: static;
		display: inline-block;
		float: none;
		margin: 15px 0 0 0;
	}
		
	#navigation, #primary-nav, #secondary-nav .quick-links { float: none; }
	#navigation, #header.extended #navigation { margin-top: 20px; }
	.js #main-menu { display: none; }
	.js .selectnav { display: block; }
	
	/* For page layout with sidebar */
	#page-content.sidebar-layout { margin: 50px 0 60px; overflow: visible; }
	#page-content.sidebar-layout .columns-wrapper { display: block; margin: 0 !important; }
	#main-content, #sidebar { display: inline; float: left; }
	#main-content { padding: 0 !important; }
	#sidebar { padding: 0 !important; background: none !important; }
	#main-content.page-right-col,
	#sidebar.page-right-col { margin-top: 50px; }
	/* end */
	
	#home-hero-content .grid-gradient-bg { display: none; }
	#home-hero-content .columns, #home-hero { width: 100%; }
	#home-hero-content { height: auto; }
	.hero-image { margin-left: 0; }
	.hero-image img, #home-hero-content .slides img  { width: 100%; }
	
	#home-hero-nav { position: static; top: auto; }
	#home-hero-nav .slider-menu { display: none; }
	#home-hero-nav .container { width: 100%; background-color: #fff; }
	#home-hero-nav .columns { position: static; width: 100%; top: auto; }
	#home-hero-nav .slider-caption {
		position: relative;
		left: auto; bottom: auto;
		max-width: 100%;
		height: 90px;
		margin: 10px auto 0;
	}
	#home-hero-nav .slider-caption > div { bottom: auto; padding: 0; background: none !important; }
	.ie8 #home-hero-nav .slider-caption > div { background: none !important; filter: none !important; }
	.slider-caption .middle-font-size,
	.slider-caption .large-font-size { font-size: 12px; line-height: 19px; }
	.slider-caption h2, .slider-caption h3 { /* h2, h3 >> h5 */
		font: 15px/23px "Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif;
		color: #444;
		font-weight: 600;
	}
	.ie8 .slider-caption h2, .ie8 .slider-caption h3 {
		font-family: OpenSansSemibold, Arial, "Helvetica Neue", Helvetica, sans-serif;
	}
	
	/* option: slider caption is not displayed on a small-screen mobile device */
	.slider-caption[data-smallscreen=no] { display: none; }
	
	.slider-caption {
		-webkit-transition: height 0.1s ease-out;
		   -moz-transition: height 0.1s ease-out;
		     -o-transition: height 0.1s ease-out;
		        transition: height 0.1s ease-out;
	}
	
	#home-intro { position: static; margin: 30px 0 40px; }
	.intro-wrapper {
		position: static;
		left: auto; top: auto; width: auto; height: auto;
		background: none !important;
	}
	.intro-wrapper:before { display: none; }
	.intro-content { padding: 0; }
	.intro-content.caption-inside .preamble p:last-of-type { margin-bottom: 0 !important; }
	.intro-content .slider-caption {
		top: 0;
		margin-bottom: 30px;
		padding-bottom: 10px;
		border-bottom: 1px dotted #ccc;
	}
	.intro-content .slider-caption > div { padding-right: 0; }
	.intro-content .preview-block { padding-top: 20px; }
	.intro-content .preview-block > [class^="thumbnail"] { margin-left: 0; }
	.intro-content .preview-block.video-type > span { float: none; margin: 0; }
	.intro-content .preview-block.video-type .preview-info { margin-top: 10px; }
	
	/* option: preview info / preview block is not displayed on a small-screen mobile device */
	.intro-content .preview-info[data-smallscreen=no],
	.intro-content .preview-block[data-smallscreen=no] { display: none; }
	
	#home-featured-content .grid-bg { padding: 30px 0 20px 0; }
	#home-featured-content .feature-boxes.left-icon-box { margin-top: 0; }
	
	#page-title.custom-bg h1 { bottom: 25px; }
	#subheader-bar .sub-nav a { font-size: 13px; line-height: 22px; }
	
	#home-slider .flex-direction-nav { display: block; }
	
	.headline .four.columns div,
	.headline .one-third.columns div { display: none; }
	
	.flex-caption { display: none; }
	
	.feature-boxes.medium-col-space,
	.feature-boxes.medium-col-space article,
	.feature-boxes.medium-col-space .columns,
	.feature-boxes.small-col-space .columns { margin-left: 0; margin-right: 0; }
	.feature-boxes article { margin: 30px 0 0; }
	.feature-boxes .row:first-child article { margin-top: 30px; }
	.feature-boxes .row:first-child .columns:first-child article { margin-top: 0 !important; }
	.feature-boxes.left-icon-box.small-icon .row:first-child article { margin-top: 25px; }
	.feature-boxes.top-icon-box article { margin-top: 30px; }
	
	/* YouTube, Vimeo and HTML5 inline video (hide preview image, disable lightbox function, and show inline player) */
	.video-preview iframe,
	.video-preview .html5-video { display: block; }
	.video-preview[data-type=youtube-video] a[data-rel=prettyPhoto],
	.video-preview[data-type=vimeo-video] a[data-rel=prettyPhoto],
	.video-preview[data-type=html5-video] a[data-rel=prettyPhoto] { display: none; }
	.item-picture iframe,
	.item-picture .html5-video { display: block; }
	.item-picture[data-type=youtube-video] .image-overlay,
	.item-picture[data-type=vimeo-video] .image-overlay,
	.item-picture[data-type=html5-video] .image-overlay,
	.item-picture[data-type=youtube-video] img,
	.item-picture[data-type=vimeo-video] img,
	.item-picture[data-type=html5-video] img { display: none; }
	/* end */
	
	#portfolio-wrapper.large-col-space,
	#portfolio-wrapper.large-col-space article { margin: 0; }
	#portfolio-wrapper.one-line .portfolio-item-preview:last-child { margin-bottom: 0; }
	#portfolio-wrapper.remove-bottom-max-767px .row:last-child .portfolio-item-preview:last-child,
	#portfolio-wrapper.remove-bottom-max-767px > .portfolio-item-preview:last-child { margin-bottom: 0; }
	.portfolio-item-preview .item-description { height: auto !important; margin-bottom: 4px; }
	#portfolio-wrapper.long-description .item-description { margin-bottom: 15px; }
	.portfolio-item-preview .item-description:after { display: none; }
	#portfolio-wrapper.one-col .portfolio-item-preview {
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 30px;
	}
	#portfolio-wrapper.one-col .item-description { margin: 15px 0 0; }
	#portfolio-wrapper.one-col .extra-tags { margin: 0; }
	#portfolio-wrapper.one-col .item-description h3 { /* h3 >> h4 */
		font-size: 16px;
		line-height: 24px;
		color: #444;
		font-weight: 600;
	}
	
	.gallery-item .item-picture[data-type=youtube-video] .item-label,
	.gallery-item .item-picture[data-type=vimeo-video] .item-label { display: none; }
	.gallery-wrapper.masonry .item-picture { width: auto !important; height: auto !important; }
	.gallery-wrapper.masonry img { position: static; max-width: 100%; }
	
	.single-item-details .one-third.columns,
	.single-item-details .four.columns { float: left; }
	.single-item-details .info-board { margin: 20px 0 0; }
	.single-item-details .info-board.colored {
		margin: 20px 0 0;
		padding: 0;
		background: none !important;;
	}
	.single-item-details .text-description {
		margin-top: 30px;
		padding: 30px 0 0 !important;
		border-top: 1px dotted #c7cfc3;
	}
	.single-item-details .text-description.white-bg {
		background: none;
		-webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none;
	}
	.single-item-details .extra-content { margin: 30px 0 0; }
	
	.team.two-cols,
	.team.two-cols article,
	.team.two-cols .columns,
	.team.three-cols,
	.team.three-cols article,
	.team.three-cols .columns,
	.team.four-cols .columns { margin-left: 0; margin-right: 0; }
	.team .row:first-child article { margin-top: 30px; }
	.team .row:first-child .columns:first-child article { margin-top: 0; }
	
	#footer .column-divider-left, #footer .column-divider-right {
		margin: 0; padding: 0; background: none;
		min-height: 0;
	}
	#footer .columns { margin-bottom: 20px !important; }
	#footer .columns:last-of-type { margin-bottom: 10px !important; }
	#footer.bottom-separated .container { padding-bottom: 20px; }
	#footer #contact-details { float: left; }
	#footer .contact-data,
	#footer .contact-description { float: none; width: auto; }
	#footer .contact-data > div { padding: 0; }
	#footer .contact-description { margin-top: 15px; }
	#footer .contact-description > div { border-left: none; padding-left: 0; }
	#footer .scroll-top { right: 0; }
	#footer-bottom .links { float: none; margin-top: 10px; }
	
	#sidebar.page-left-col .widget:first-child + .widget { margin-top: 40px; }
	#sidebar.page-right-col .widget:first-child:not(.separator) { padding-top: 40px; border-top: 2px solid #dedede; }
	#sidebar .widget h4, #sidebar .widget h5 { margin-bottom: 5px; }
	.side-menu { margin: 0 !important; background: none !important; }
	.side-menu li { border-top: 1px dotted #c7cfc3; }
	.side-menu li:first-child { border-top: none; }
	#sidebar .widget h4 + .side-menu,
	#sidebar .widget h5 + .side-menu { padding-top: 10px; }
	.side-menu li:hover, .side-menu li.current { background: none !important; margin: 0 !important; }
	.side-menu li a { padding: 9px 0 10px !important; background: none !important; text-align: left !important; }
	.side-menu li a:after {	display: none !important; }
	.side-menu li.current a, .side-menu.middle-font-size li.current a:hover { color: #444; }
	
	/* Option: a particular widget is not displayed on a small-screen mobile device */
	#sidebar .widget[data-smallscreen=no] { display: none; }
	
	.blog .post-meta .fa { font-size: 13px; line-height: 13px; margin-right: 5px; }
	.blog.medium-size .post-media {
		float: none;
		width: auto;
		height: auto !important;
		margin-bottom: 20px;
	}
	.blog.medium-size .post-content { margin-left: 0; }
	.blog-grid-style .post-content { height: auto !important; margin-bottom: 20px; }
	.blog-grid-style .post-content:after { display: none; }
	.blog-grid-style.one-line .post-preview:last-child { margin-bottom: 0; }
	
	.announcements section.adapted-max-767px article:first-child {
		border-top: 1px dotted #c7cfc3; margin-top: 25px; padding-top: 20px;
	}
	.announcements section.adapted-max-767px:first-child article:first-child {
		border: none; margin-top: 0; padding-top: 0;
	}
	.announcements .row:first-child article { border-top: 1px dotted #c7cfc3; margin-top: 25px; padding-top: 20px; }
	.announcements .row:first-child .columns:first-child article { border: none; margin-top: 0; padding-top: 0; }
	
	.info-box.adapted-box {
		padding: 0;
		background: none !important;
		border: none;
		-webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none;
		-webkit-border-radius: 0; -moz-border-radius: 0; border-radius: 0;
	}
	.info-box.tight { padding: 20px; }
	.info-box .action-box-container { display: block; width: auto; }
	.info-box .action-box-container > div { display: block; }
	.info-box .action-button { margin: 20px 0 10px; padding: 0; text-align: left; }
	
	.white-bg .tabs-container.bordered { border-bottom-color: #c7cfc3; }
	
	ul.feature-nav.inline li { float: none; margin-right: 0; }
	
	.pricing-table .table-column:first-child .price,
	.pricing-table.extended .features-list + .table-column .price { border-left: none; }
	.pricing-table .table-column:last-child .price { border-right: none; }
	.pricing-table .table-column:last-child .features { border-right: none; }
	.pricing-table .table-column { margin-bottom: 30px; }
	
	.contact-form-wrapper { margin: 0; padding: 0; background: none; min-height: 0; }
	
	.error-404 { font-size: 160px; line-height: 160px; }
}

/* Mobile Landscape Size (devices and browsers)
---------------------------------------------------------------------- */
@media only screen and (min-width: 480px) and (max-width: 767px) {
	
	/* For page layout with sidebar */
	#main-content.page-left-col,
	#main-content.page-right-col,
	#sidebar.page-left-col,
	#sidebar.page-left-col .widget,
	#sidebar.page-right-col,
	#sidebar.page-right-col .widget { width: 420px; }
	
	#home-hero-nav .slider-caption { width: 420px; }
	.intro-content .slider-caption { height: 100px; }
	
	#page-title.custom-bg { height: 280px; }
	
	.feature-boxes.top-icon-box img { float: left; width: 200px; margin: 0 15px 0 0 !important; }
	.feature-boxes.top-icon-box img[data-scale=no] { width: auto; }
	.feature-boxes.top-icon-box h4, .feature-boxes.top-icon-box h5 { margin: 0 0 10px; }
	.feature-boxes.top-icon-box article > div { display: table-cell; }
	.feature-boxes.top-icon-box.centered article { text-align: left; }
	.feature-boxes.top-icon-box.centered article p { margin: 10px 0 0; }
	
	.feature-boxes.top-icon-box [class^="thumbnail"],
	.feature-boxes.top-icon-box [class*=" thumbnail"] {
		float: left;
		width: 200px;
		margin-right: 15px;
	}
	.feature-boxes.top-icon-box [class^="thumbnail"] img,
	.feature-boxes.top-icon-box [class*=" thumbnail"] img {
		float: none;
		width: auto;
		margin: 0 !important;
	}
	.feature-boxes.top-icon-box.short-description img,
	.feature-boxes.top-icon-box.short-description [class^="thumbnail"],
	.feature-boxes.top-icon-box.short-description [class*=" thumbnail"] {
		float: none !important;
		width: auto !important;
		margin-right: 0 !important;
	}
	.container .feature-boxes.top-icon-box.short-description .columns {
		width: 340px;
		margin-left: 40px;
		margin-right: 40px;
	}
	
	/* Set dimensions for YouTube, Vimeo and HTML5 inline video */
	#portfolio-wrapper .item-picture iframe,
	#portfolio-wrapper .html5-video,
	#portfolio-wrapper .html5-video video,
	#projects-carousel .item-picture iframe,
	#projects-carousel .html5-video,
	#projects-carousel .html5-video video,
	.gallery-wrapper .item-picture iframe,
	.gallery-wrapper .html5-video,
	.gallery-wrapper .html5-video video,
	.blog-grid-style .item-picture iframe,
	.blog-grid-style .html5-video,
	.blog-grid-style .html5-video video {
		width: 340px;
		height: 191px; /* calculated for 16/9 aspect-ratio */
		max-width: 100%;
	}
	.video-preview iframe,
	.video-preview .html5-video,
	.video-preview .html5-video video,
	.item-picture iframe,
	.item-picture .html5-video,
	.item-picture .html5-video video {
		width: 420px;
		height: 236px; /* calculated for 16/9 aspect-ratio */
		max-width: 100%;
	}
	
	/* Set dimensions for flash video (fallback from HTML5 inline video) */
	#portfolio-wrapper .html5-video span.player,
	#projects-carousel .html5-video span.player,
	.blog-grid-style .html5-video span.player {
		width: 340px !important;
		height: 191px !important; /* calculated for 16/9 aspect-ratio */
	}
	.video-preview .html5-video span.player,
	.item-picture .html5-video span.player {
		width: 420px !important;
		height: 236px !important; /* calculated for 16/9 aspect-ratio */
	}
	
	/* Multi-column layout >> 1 column */
	/* portfolio, projects carousel, team and blog-grid-style */
	#portfolio-wrapper .columns,
	#projects-holder .carousel-frame,
	.gallery-wrapper .columns,
	.team .columns,
	.blog-grid-style .columns {
		width: 340px !important;
		margin-left: 40px !important;
		margin-right: 40px !important;
	}
	
	#projects-carousel.two-slides li.portfolio-item-preview,
	#projects-carousel.three-slides li.portfolio-item-preview,
	#projects-carousel.four-slides li.portfolio-item-preview,
	#main-content.page-left-col #projects-carousel.three-slides li.portfolio-item-preview,
	#main-content.page-left-col #projects-carousel.four-slides li.portfolio-item-preview,
	#main-content.page-right-col #projects-carousel.three-slides li.portfolio-item-preview,
	#main-content.page-right-col #projects-carousel.four-slides li.portfolio-item-preview { width: 340px; }
	
	#footer input[type="email"] { width: 377px; }
	#info-share input[type="email"] { width: 165px; }
	.ie8 #info-share #subscription-form,
	#info-share .footer-message { width: 208px; }
	
	.comment .children { padding-left: 55px; }
	
	.pricing-table .table-column { width: 210px !important; }
	aside .pricing-table .table-column { width: 420px !important; }
	.pricing-table.basic .table-column:nth-child(odd) .header,
	.pricing-table.basic .table-column:nth-child(odd) .features,
	.pricing-table.basic .table-column:nth-child(odd) .footer,
	.pricing-table.extended .table-column:nth-child(even) .header,
	.pricing-table.extended .table-column:nth-child(even) .features,
	.pricing-table.extended .table-column:nth-child(even) .footer { border-left: none; }
	aside .pricing-table .table-column:first-child .features { border-left: none; }
	
	.google-map { height: 230px; }
	
	.list-of-logos li { width: 210px; }
	
	table.standard th, table.standard td { padding-left: 10px; padding-right: 10px; }
	
	#sidebar.page-left-col .fb-like-box > span,
	#sidebar.page-left-col .fb-like-box iframe { width: 340px !important; height: 190px !important; }
}

/* Mobile Portrait Size (devices and browsers)
---------------------------------------------------------------------- */
@media only screen and (max-width: 479px) {
	h1 { font-size: 36px; line-height: 40px; }
	h2 { font-size: 30px; line-height: 36px; }
	
	#top-bar,
	#secondary-nav { display: none; }
	
	/* For page layout with sidebar */
	#main-content.page-left-col,
	#main-content.page-right-col,
	#sidebar.page-left-col,
	#sidebar.page-left-col .widget,
	#sidebar.page-right-col,
	#sidebar.page-right-col .widget { width: 300px; }
	
	#home-hero-nav .slider-caption { width: 300px; }
	.intro-content .slider-caption { height: 120px; }
	.brief-nav li { padding: 0 7px 0 13px; }
	
	#page-title.custom-bg { height: 200px; }
	
	/* Set dimensions for YouTube, Vimeo and HTML5 inline video */
	.video-preview iframe,
	.video-preview .html5-video,
	.video-preview .html5-video video,
	#portfolio-wrapper .item-picture iframe,
	#portfolio-wrapper .html5-video,
	#portfolio-wrapper .html5-video video,
	#projects-carousel .item-picture iframe,
	#projects-carousel .html5-video,
	#projects-carousel .html5-video video,
	.gallery-wrapper .item-picture iframe,
	.gallery-wrapper .html5-video,
	.gallery-wrapper .html5-video video,
	.blog-grid-style .item-picture iframe,
	.blog-grid-style .html5-video,
	.blog-grid-style .html5-video video,
	.item-picture iframe,
	.item-picture .html5-video,
	.item-picture .html5-video video {
		width: 300px;
		height: 169px; /* calculated for 16/9 aspect-ratio */
		max-width: 100%;
	}
	
	/* Set dimensions for flash video (fallback from HTML5 inline video) */
	.video-preview .html5-video span.player,
	#portfolio-wrapper .html5-video span.player,
	#projects-carousel .html5-video span.player,
	.blog-grid-style .html5-video span.player,
	.item-picture .html5-video span.player {
		width: 300px !important;
		height: 169px !important; /* calculated for 16/9 aspect-ratio */
	}
	
	#portfolio-wrapper .columns,
	.blog-grid-style .columns { margin-left: 0 !important; margin-right: 0 !important; }
	
	#projects-carousel.two-slides li.portfolio-item-preview,
	#projects-carousel.three-slides li.portfolio-item-preview,
	#projects-carousel.four-slides li.portfolio-item-preview,
	#main-content.page-left-col #projects-carousel.three-slides li.portfolio-item-preview,
	#main-content.page-left-col #projects-carousel.four-slides li.portfolio-item-preview,
	#main-content.page-right-col #projects-carousel.three-slides li.portfolio-item-preview,
	#main-content.page-right-col #projects-carousel.four-slides li.portfolio-item-preview { width: 300px; }
	
	#footer .form-wrapper { min-height: 0; }
	#info-share .form-wrapper { margin-right: 10px; }
	#footer input[type="email"] { width: 257px; }
	#info-share input[type="email"] { width: 95px; }
	.ie8 #info-share #subscription-form,
	#info-share .footer-message { width: 138px; }
	
	.blog .post-media { margin-bottom: 15px; }
	.blog .post-date { position: relative; float: none; width: auto; padding-bottom: 10px; }
	.blog .post-date span {
		display: inline;
		font-size: 11px !important;
		color: inherit !important;
		font-weight: normal !important;
		font-style: italic;
		background: none !important;
		margin: 0 !important;
		padding: 0 !important;
	}
	.ie8 .blog .post-date .day { font-family: "Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif; }
	.ie8 .blog .post-date span { font-family: OpenSansItalic, Arial, "Helvetica Neue", Helvetica, sans-serif; }
	.blog .post-date:before {
		position: absolute;
		left: 0;
		bottom: 2px;
		width: 30px;
		height: 0;
		content: '\0020';
		border-bottom: 1px solid #ccc;
	}
	.blog .post-content { margin: 10px 0 0; }
	.blog .post-meta { display: none; }
	.blog.medium-size .post-content { margin: 0; }
	.blog.medium-size .post-meta { display: block; }
	.blog.small-size .post-media {
		float: none;
		width: auto;
		margin-bottom: 20px;
	}
	.blog.small-size .post-content { margin-left: 0; }
	.blog.small-size .post-meta { display: block; }
	.comment .children { padding-left: 40px; }
	.comment .date { font-size: 11px; }
	.comment .comment-content { margin-left: 0; clear: left; }
	
	.announcements img { float: none; margin-right: 0; }
	.announcements .preview-text { margin-top: 10px; }
	.announcements .preview-text h5,
	.announcements .preview-text h6 { margin-bottom: 7px; }
	.announcements .preview-text p { margin-bottom: 10px; }
	.announcements [class^="thumbnail"],
	.announcements [class*=" thumbnail"] {
		float: none;
		display: inline-block;
		margin: 0;
	}
	
	aside .announcements img,
	aside .announcements [class^="thumbnail"],
	aside .announcements [class*=" thumbnail"] { float: left; }
	aside .announcements .preview-text { margin-top: 0; }
	aside .announcements .preview-text h5,
	aside .announcements .preview-text h6,
	aside .announcements .preview-text p { margin-bottom: 5px; }
	
	.tabs-nav li.active:before { display: none; }
	
	#sidebar .flickr-widget, #sidebar .preview-images-list { margin: 0 0 -15px 0; }
	#sidebar .flickr-widget .flickr_badge_image,
	#sidebar .preview-images-list a { margin: 0 15px 15px 0; }
	
	.pricing-table .table-column { width: 300px !important; padding: 0; }
	.pricing-table .header,
	.pricing-table .features,
	.pricing-table .footer { border-left: none !important; }
	
	.google-map { height: 340px; }
	
	.list-of-logos li { width: 150px; }
	
	table.standard th, table.standard td { padding-left: 7px; padding-right: 7px; }
	table.standard th { font-weight: 600; }
	table.standard th, table.standard td { font-size: 11px; line-height: 18px;}
	.ie8 table.standard th { font-family: OpenSansSemiBold, Arial, "Helvetica Neue", Helvetica, sans-serif; }
}
